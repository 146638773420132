/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-13 13:26:56
 * @Module:外部公司 备选公司（添加案件）
 */
 <template>
  <el-select filterable style="width:100%;" value-key="uuid" :value="value" placeholder="请选择诉讼主体" @change="change" :filter-method="filterMethod" :clearable="clearable">
    <el-option v-for="item in options" :key="item.uuid" :label="item.name" :value="item.uuid">
      <span style="float: left">{{ item.name }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.number }}</span>
    </el-option>
  </el-select>
</template>
 <script>
export default {
  components: {},
  props: {
    value: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      list: [],
      options: []
    };
  },
  mounted () {
    this.getCompanyAll();
  },
  methods: {
    async getCompanyAll () {
      const { status, data } = await this.$api.getOuterCompanyAllList()
      if (status === 200) {
        this.list = data;
        this.options = data;
      }
    },
    change (res) {
      this.$emit('input', res)
    },
    filterMethod (res) {
      if (res) {
        this.options = this.list.filter(item => {
          if (item.name.indexOf(res) > -1) {
            return true
          } else if (item.number && item.number.indexOf(res) > -1) {
            return true
          } else {
            return false
          }
        })
      } else {
        this.options = this.list
      }

    }
  }
};
 </script>
 <style lang='scss' scoped>
</style>