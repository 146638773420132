/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-13 18:03:30
 * @Module: 团队
 */
 <template>
  <div>
    <el-autocomplete class="inline-input" style="width: 100%" :value="value" @input="change" :fetch-suggestions="querySearch" placeholder="请输入团队" :clearable="clearable" @select="handleSelect"></el-autocomplete>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    value: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    team: {
      type: String,
      default: ''
    }
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {
    change (res) {
      this.$emit("input", res);
    },
    querySearch (queryString, cb) {
      const team = this.team || '中能团队,仲管团队';
      const restaurants = team.split(',').map(item => ({ label: item, value: item }))
      var results = queryString
        ? restaurants.filter((item) => item.value.indexOf(queryString) == 0)
        : restaurants;
      console.log(results);
      cb(results);
    },
    handleSelect (item) {
      console.log(item);
    },
  },
};
</script>
 <style lang='scss' scoped>
</style>