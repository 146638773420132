import utils from "./utils"
import api from '@/utils/api'
const install = (Vue) => {
    //api
    Vue.prototype.$api = api;
    Vue.prototype.$utils = utils;
    // 时间格式化
    Vue.filter('timeFormat', (timestamp, format) => utils.timeFormat(timestamp, format))
    // 将多久以前的方法，注入到全局过滤器
    Vue.filter('timeFrom', (timestamp, format) => utils.timeFrom(timestamp, format))
    Vue.filter('formatBytes', (a, b) => utils.formatBytes(a, b))
    Vue.filter('sliceUrl', (str) => str.slice(44))
    Vue.filter('priceFormat', (number, decimals, decimalPoint, thousandsSeparator) => utils.priceFormat(number, decimals, decimalPoint, thousandsSeparator))
    // 全局组件
    // Vue.component('hdqSection', hdqSection)

}
export default install