/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-06 12:10:40
 * @Module: 底部
 */
 <template>
  <footer> ©2014-2022 中能魔力（河南）网络科技有限公司版权所有 豫ICP备14100822号-1 豫公网安备 44030602004264号文本 </footer>
</template>
 <script>
export default {
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
footer {
  line-height: 60px;
  text-align: center;
  font-size: 12px;
}
</style>