/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-13 15:49:50
 * @Module: 合同状态
 */
 <template>
  <div>
    <el-select :value="value" placeholder="请选择合同状态" clearable @change="change" style="width:100%">
      <el-option v-for="item in [{
				value:'UNSIGNED',
				label:'无合同'
			},
			{
				value:'NORMAL',
				label:'正常'
			},
			{
				value:'CANCELLED',
				label:'解除'
			},
			{
				value:'EXPIRED',
				label:'到期'
			},
				{
				value:'AAA',
				label:'AAA'
			}]" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>
 <script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {
    change (value) {
      this.$emit('input', value)
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>