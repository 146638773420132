/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-06 16:46:01
 * @Module: 动态列表
 */
 <template>
  <div class="DynamicStateList">
    <div class="title">
      <span>{{title}}</span>
      <span>
        <el-button type="primary" size="small" v-if="hidden" @click="add">添加动态</el-button>
      </span>
    </div>
    <!-- <el-table size="mini" :data="list" stripe border style="width: 100%">
      <el-table-column label="时间" width="120">
        <template slot-scope="scope">
          <span>{{scope.row.createdAt|timeFormat}}</span>
        </template>
      </el-table-column>
      <el-table-column label="发言人" width="120">
        <template slot-scope="scope">
          <span>{{scope.row.creatorName}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="companyName" label="具体客户" v-if="!hidden" width="200">
        <template slot-scope="scope">
          <span class="moving" @click="$router.push({path:'/customerManagement/'+scope.row.companyUuid})">{{scope.row.companyName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="内容">
        <template slot-scope="scope">
          <span style="display:flex;align-items: flex-end;">
            <span class="pointer moving content keep2lines" @click="$router.push({path:'/trend/'+scope.row.uuid})">
              {{scope.row.content}}
            </span>
            <imagePreview :imageUrls="scope.row.imageUrls" />
          </span>

        </template>
      </el-table-column>
    </el-table> -->
    <div class="content" :style="`height:${height - 56}px`">
      <div class="item" v-for="item in list" :key="item.uuid">
        <div class="top">
          <span class="info">{{item.creatorName}} </span>
          <span style="color: #666666;" v-if="!hidden">评论</span>
          <span v-if="!hidden" class="pointer primary" @click="$router.push({path:'/customerManagement/'+item.companyUuid})"> {{item.companyName}}</span>
        </div>
        <div class="time">{{item.createdAt|timeFormat}}</div>
        <div class="bottom" @click="$router.push({path:'/trend/'+item.uuid})">
          {{item.content}}
          <!-- <span style="display:flex;align-items: flex-end;">
            <span class="pointer moving content keep2lines" @click="$router.push({path:'/trend/'+scope.row.uuid})">

            </span>
            <imagePreview :imageUrls="scope.row.imageUrls" />
          </span> -->
        </div>
        <imagePreview :imageUrls="item.imageUrls" style="margin-top:6px;" />
      </div>
      <el-empty v-if="list.length==0"></el-empty>
    </div>
  </div>

</template>
 <script>
import imagePreview from "@/components/imagePreview"
export default {
  components: {
    imagePreview
  },
  props: {
    hidden: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => ([])
    },
    title: {
      type: String,
      default: '本辖区动态看板'
    },
    height: {
      type: Number,
      default: 404
    }
  },
  data () {
    return {};
  },
  filters: {
    imageUrls (str) {
      return str.split(',').map(item => "https://oss.mcn-open.com/" + item)
    }
  },
  computed: {

  },
  mounted () { },
  methods: {
    add () {
      this.$emit('add')
    }
  },
};
 </script>
 <style lang='scss' scoped>
.DynamicStateList {
  // width: 200px;
  background: #fff;
  .title {
    line-height: 56px;
    padding: 0 16px;
    font-size: 16px;
    color: #262626;
    display: flex;
    justify-content: space-between;
    .btn {
      font-size: 14px;
      color: #0d72ff;
    }
  }
  .content {
    padding: 0 16px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    .item {
      background: #f7f8f9;
      border-radius: 4px;
      padding: 14px;
      margin-bottom: 16px;
      .top {
        font-size: 14px;
        line-height: 21px;
        color: #262626;
      }
      .time {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #999999;
        margin-top: 6px;
      }
      .bottom {
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        margin-top: 6px;
        color: #666666;
      }
    }
  }
}
</style>