/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-13 17:00:16
 * @Module: 案件赔付表单
 */
 
 <template>
  <el-dialog :title="type=='add'?'添加案件赔付':type=='revision'?'修改案件赔付':''" :visible.sync="dialogVisible" width="800px" @closed="closed">
    <div style="padding:20px;">
      <el-form size="mini" label-position="top" :model="form" ref="form">
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="批次" prop="batch">
              <el-input v-model="form.batch" placeholder="请输入批次"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="应付金额" prop="payable">
              <el-input v-model.number="form.payable" placeholder="请输入应付金额"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实付金额" prop="payment">
              <el-input v-model.number="form.payment" placeholder="请输入实付金额"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="剩余" prop="rest">
              <el-input v-model.number="form.rest" placeholder="请输入剩余金额"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否到帐" prop="received">
              <el-select v-model="form.received" placeholder="请选择" style="width:100%;">
                <el-option label="已到账" :value="true" />
                <el-option label="未到账" :value="false" />
              </el-select>
              <!-- <el-radio-group v-model="form.received">
                <el-radio :label="true">已到账</el-radio>
                <el-radio :label="false">未到账</el-radio>
              </el-radio-group> -->
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="note">
              <el-input v-model="form.note" type="textarea" :autosize="{minRows: 4, maxRows: 12}" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
 <script>
export default {

  data () {

    return {
      type: 'add',
      dialogVisible: false,
      form: {
        batch: '',
        payable: '',
        payment: '',
        received: false,
        rest: '',
        note: '',
        lawsuitUuid: ''
      }
    };
  },
  mounted () { },
  methods: {
    show ({ type = 'add', data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
      } else if (type == "add") {
        this.lawsuitUuid = data.uuid
      }
      this.type = type
      this.dialogVisible = true
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.postLawsuitPhase()
        } else {
          return false
        }
      })
    },
    close () {
      // this.form = { ...this.form, ...this.oldform };
      this.dialogVisible = false
    },
    closed () {
      this.form = {
        batch: '',
        payable: '',
        payment: '',
        received: false,
        rest: '',
        note: ''
      }
    },
    async postLawsuitPhase () {
      const api = {
        revision: {
          func: this.$api.putChangeLawsuitPayment,
          params: {
            ...this.form
          }
        },
        add: {
          func: this.$api.postLawsuitPayment,
          params: {
            ...this.form,
            lawsuitUuid: this.lawsuitUuid
          }
        }
      }

      const { status, data, info } = await api[this.type].func(api[this.type].params)
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success', this.type)
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
</style>