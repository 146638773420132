/* 
 * @Author: 曹俊杰 
 * @Date: 2022-10-12 16:25:10
 * @Module: 撤销提现申请
 */
 <template>
  <el-dialog title="撤销提现申请" :visible.sync="dialogVisible" width="800px" @closed="closed">
    <div style="padding:20px;">
      <el-form size="mini" label-position="top" label-width="120px" :model="form" ref="form">
        <el-row :gutter="30">
          <el-col :span="24">
            <el-form-item label="撤销原因" prop="comments">
              <el-input v-model="form.comments" type="textarea" :autosize="{minRows: 4, maxRows: 12}" placeholder="请输入撤销原因"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
 <script>
export default {
  components: {
  },
  data () {

    return {
      type: 'add',
      dialogVisible: false,
      form: {
        comments: '',
        applicationUuid: null
      },

    };
  },
  mounted () { },
  methods: {
    show ({ data }) {
      let form = this.$utils.deepClone(data);
      this.form.applicationUuid = form.uuid
      this.dialogVisible = true;
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.putPercentageCancel()
        } else {
          return false
        }
      })
    },
    close () {
      this.dialogVisible = false
    },
    closed () {
      this.form = {
        comments: '',
        applicationUuid: null
      }
    },
    async putPercentageCancel () {
      const { status, data, info } = await this.$api.putPercentageCancel(this.form)
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success')
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
</style>