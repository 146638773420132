/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-08 13:36:54
 * @Module: 创建公司
 */
<template>
  <div class="add">
    <el-dialog :append-to-body="true" :title="type=='revision'?'编辑公司':type=='add'?'创建公司':''" :visible.sync="dialogVisible" :size="670">
      <div style="overflow-y: scroll;height:500px;">

        <el-form style="padding:0 20px; " size="mini" label-position="top" label-width="120px" :model="form" ref="form" :rules="rules">
          <div style="line-height: 40px;font-size: 14px;padding:0 14px;background: #F7F8F9;margin:10px 0;">公司信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="公司全称" prop="name">
                <el-input v-model="form.name" placeholder="请输入公司全称"></el-input>
              </el-form-item>
              <el-form-item label="经营地" prop="areaCode">
                <areaCode v-model="form.areaCode" />
              </el-form-item>

              <el-form-item label="对接法务" prop="legalUuid">
                <legal v-model="form.legalUuid" />
              </el-form-item>
              <el-form-item label="签约时间" prop="signedAt">
                <el-date-picker value-format="timestamp" style="width:100%;" v-model="form.signedAt" type="date" placeholder="选择签约时间" />
              </el-form-item>
              <el-form-item label="主营业务" prop="businessIds">
                <business v-model="form.businessIds" />
              </el-form-item>
              <el-form-item label="流水规模" prop="scaleId">
                <!-- <el-select style="width:100%;" value-key="scaleId" v-model="form.scaleId" placeholder="请选择流水规模">
              <el-option v-for="item in scaleList" :key="item.scaleId" :label="item.scale" :value="item.scaleId">
              </el-option>
            </el-select> -->
                <scale v-model="form.scaleId" />
              </el-form-item>

              <el-form-item label="主做平台" prop="platformIds">
                <platform v-model="form.platformIds" />
              </el-form-item>

            </el-col>

            <el-col :span="12">
              <el-form-item label="对接群名" prop="groupName">
                <el-input v-model="form.groupName" placeholder="请输入对接群名"></el-input>
              </el-form-item>
              <el-form-item label="谁签约的" prop="signerUuid">
                <accountShare v-model="form.signerUuid" />
              </el-form-item>
              <el-form-item label="合同状态" prop="signState">
                <signState v-model="form.signState" />
                <!-- <el-select style="width:100%;" v-model="form.signState" placeholder="请选择合同状态">
                  <el-option v-for="item in [
                  {
                  	label:'无合同',
                  	value:'UNSIGNED'
                  },{
                  	label:'正常',
                  	value:'NORMAL'
                  },{
                  	label:'解除',
                  	value:'CANCELLED'
                  }]" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select> -->
              </el-form-item>
              <el-form-item label="合同到期时间" prop="expiredAt">
                <el-date-picker style="width:100%;" value-format="timestamp" v-model="form.expiredAt" type="date" placeholder="选择合同到期时间" />
              </el-form-item>
              <!-- <el-form-item label="预存余额" prop="balance">
                <el-input v-model.number="form.balance" oninput ="value=value.replace(/[^\d]/g,'')" placeholder="请输入预存余额"></el-input>
              </el-form-item> -->
              <el-form-item label="客户基本情况" prop="situation">
                <el-input type="textarea" :autosize="{minRows: 4, maxRows: 12}" v-model="form.situation" placeholder="请输入客户基本情况"></el-input>
              </el-form-item>
              <!-- <el-form-item label="关联公司" prop="related" :rules="{ required: true, message: '关联公司不能为空'}">
            <related v-model="form.related" />
          </el-form-item> -->
              <el-form-item label="税咨" prop="tax">
                <el-select v-model="form.tax" style="width:100%;">
                  <el-option label="开" :value="true">
                  </el-option>
                  <el-option label="关" :value="false">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="合同文件" prop="contractUrls">
                <contractUrls v-model="form.contractUrls" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="营业执照" prop="licenseUrl">
                <imageUrls :limit="1" :multiple="false" v-model="form.licenseUrl" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="公司图片" prop="imageUrls">
                <imageUrls v-model="form.imageUrls" />
              </el-form-item>
            </el-col>
          </el-row>
          <div style="line-height: 40px;font-size: 14px;padding:0 14px;background: #F7F8F9;margin:10px 0;">关联公司</div>
          <el-button type="text" v-show="form.relatedCompanys.length==0" @click="addRelatedCompanys">添加关联公司</el-button>
          <el-row :gutter="20" align="middle">
            <el-col :span="22">
              <el-form-item v-for="(item, index) in form.relatedCompanys" label-width="0" :key="item.key" :prop="'relatedCompanys.' + index + '.name'" :rules="{
                  required: true, message: '关联公司不能为空', trigger: 'blur'
                }">
                <el-input v-model="item.name" placeholder="输入关联公司"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <div style="padding-top:8px;height:48px;" v-for="item in form.relatedCompanys" :key="item.uuid">
                <i class="el-icon-remove-outline" @click="removeRelatedCompanys(item)"></i>
                <i class="el-icon-circle-plus-outline" @click="addRelatedCompanys"></i>
              </div>
            </el-col>
          </el-row>
          <div style="line-height: 40px;font-size: 14px;padding:0 14px;background: #F7F8F9;margin:10px 0;">分账明细</div>
          <el-button type="text" v-show="form.partners.length==0" @click="addPartners">添加分账明细</el-button>
          <el-row :gutter="20" align="middle">
            <el-col :span="11">
              <el-form-item v-for="(item, index) in form.partners" label-width="0" :key="item.key" :prop="'partners.' + index + '.accountUuid'" :rules="{
                  required: true, message: '分账账号不能为空', trigger: 'blur'
                }">
                <accountShare v-model="item.accountUuid" />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item v-for="(item, index) in form.partners" label-width="0" :key="item.uuid" :prop="'partners.' + index + '.ratio'" :rules="{
                  required: true, message: '分账比不能为空', trigger: 'blur'
                }">
                <el-input v-model.number="item.ratio" placeholder="输入分账比" type='number'>
                  <i slot="suffix">%</i>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <div style="padding-top:8px;height:48px;" v-for="item in form.partners" :key="item.uuid">
                <i class="el-icon-remove-outline" @click="removePartners(item)"></i>
                <i class="el-icon-circle-plus-outline" @click="addPartners"></i>
              </div>
            </el-col>
          </el-row>
          <div style="line-height: 40px;font-size: 14px;padding:0 14px;background: #F7F8F9;margin:10px 0;">客户联络人</div>
          <el-button type="text" v-show="form.contacts.length==0" @click="addContacts">添加客户联络人</el-button>
          <el-row :gutter="20" align="middle">
            <el-col :span="6">
              <el-form-item v-for="(item, index) in form.contacts" label-width="0" :key="item.uuid" :prop="'contacts.' + index + '.name'">
                <el-input v-model="item.name" placeholder="请输入姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item v-for="(item, index) in form.contacts" label-width="0" :key="item.uuid" :prop="'contacts.' + index + '.phone'">
                <el-input v-model="item.phone" placeholder="请输入电话"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item v-for="(item, index) in form.contacts" label-width="0" :key="item.uuid" :prop="'contacts.' + index + '.wx'">
                <el-input v-model="item.wx" placeholder="请输入微信"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item v-for="(item, index) in form.contacts" label-width="0" :key="item.uuid" :prop="'contacts.' + index + '.position'">

                <el-select v-model="item.position" placeholder="请选择职务" filterable allow-create>
                  <el-option key="法人" label="法人" value="法人" />
                  <el-option key="负责人" label="负责人" value="负责人" />
                  <el-option key="股东" label="股东" value="股东" />
                </el-select>

                <!-- <el-input v-model="item.position" placeholder="请输入职务"></el-input> -->
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <div style="padding-top:8px;height:48px;" v-for="item in form.contacts" :key="item.uuid">
                <i class="el-icon-remove-outline" @click="removeContacts(item)"></i>
                <i class="el-icon-circle-plus-outline" @click="addContacts"></i>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <div style="height:80px;width:100%;display:flex;justify-content: flex-end;align-items: center;padding:0 24px;">
          <el-button type="primary" size="small" @click="submit" style="float:right">提交</el-button>

        </div>
      </div>

    </el-dialog>
  </div>
</template>
<script>
import contractUrls from "./form/contractUrls"
import imageUrls from "./form/imageUrls"
import legal from "./form/legal"
import areaCode from "./form/areaCode"
import accountShare from "./form/accountShare"
// import related from "./form/related"
import business from "./form/business"
import platform from "./form/platform"
import signState from "./form/signState"
import scale from "./form/scale"
export default {
  components: {
    contractUrls,
    imageUrls,
    legal,
    areaCode,
    accountShare,
    // related,
    business,
    platform,
    scale,
    signState
  },
  data () {
    const phoneValidator = (_, value, callback) => {
      if (this.$utils.test.mobile(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的手机号'));
      }
    }
    return {
      // scaleList: [],
      dialogVisible: false,
      form: {
        name: null,
        areaCode: null,
        businessIds: [],
        legalUuid: null,
        groupName: null,
        signerUuid: null,
        signState: null,
        signedAt: null,
        expiredAt: null,
        partners: [],
        caseCount: null,
        balance: null,
        situation: null,
        // related: [],
        relatedCompanys: [],
        platformIds: [],
        contractUrls: null,
        licenseUrl: null,
        imageUrls: null,
        contacts: [],
        uuid: null,
				tax:false
      },
      //rules前端验证
      rules: {

        name: [
          { required: true, message: '公司全称不能为空', trigger: 'blur' },
        ],
      },
      type: "add"
    };
  },
  mounted () {
    // this.getScaleList();
  },
  methods: {
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.putCompany()
        } else {
          return false
        }
      })
    },
    show ({ type = 'add', data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
        console.log(data)
      }
      this.type = type
      this.dialogVisible = true
    },
    // 添加分账明细
    addPartners () {
      this.form.partners.push({
        accountUuid: '',
        ratio: '',
        uuid: Date.now()
      });
    },
    addContacts () {
      this.form.contacts.push({
        name: '',
        phone: '',
        wx: '',
        position: '',
        uuid: Date.now()
      });
    },
    addRelatedCompanys () {
      this.form.relatedCompanys.push({
        name: '',
        uuid: Date.now()
      });
    },
    // 删除分账明细
    removePartners (item) {
      var index = this.form.partners.indexOf(item)
      // if (index > 0) {
      this.form.partners.splice(index, 1)
      // }
    },
    removeContacts (item) {
      var index = this.form.contacts.indexOf(item)
      // if (index > 0) {
      this.form.contacts.splice(index, 1)
      // }
    },
    removeRelatedCompanys (item) {
      var index = this.form.relatedCompanys.indexOf(item)
      // if (index > 0) {
      this.form.relatedCompanys.splice(index, 1)
      // }
    },
    async putCompany () {
      // let { related } = this.form
      let api = {
        revision: {
          func: this.$api.putChangeCompany,
          params: {
            ...this.form,
            uuid: this.$route.params.uuid || this.form.uuid,
            // related: related.toString()
          }
        },
        add: {
          func: this.$api.putCompany,
          params: {
            ...this.form,
            // related: related.toString()
          }
        }
      }
      const { status, data, info } = await api[this.type].func(api[this.type].params)
      if (status === 200) {
        this.$message({
          type: "success",
          message: info
        })
        this.dialogVisible = false;
        this.$emit('success')
      } else {
        this.$message({
          type: "error",
          message: info
        })
      }
    },
    // async getScaleList () {
    //   const { status, data } = await this.$api.getScaleList();
    //   if (status === 200) {
    //     this.scaleList = data;
    //   }
    // },
    closed () {
      this.form = {
        name: null,
        areaCode: null,
        businessIds: [],
        legalUuid: null,
        groupName: null,
        signerUuid: null,
        signState: null,
        signedAt: null,
        expiredAt: null,
        partners: [],
        caseCount: null,
        balance: null,
        situation: null,
        // related: [],
        relatedCompanys: [],
        platformIds: [],
        contractUrls: null,
        licenseUrl: null,
        imageUrls: null,
        contacts: [],
        uuid: null
      }
    },
  },
};
</script>
<style lang='scss' scoped>
/deep/ {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    height: auto;
    -webkit-appearance: none;
  }
}
</style>
