/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-11 14:03:16
 * @Module: 合同文件
 */
 <template>
  <div>
    <div v-if="value">
      <el-tag style="margin-right:5px;" v-for="tag in list" :key="tag" closable size="mini" @close="()=>close(tag)">
        {{tag|sliceUrl}}
      </el-tag>
    </div>
    <el-button icon="el-icon-upload2" type="text" size="mini" @click="showUploadDialog">点击上传</el-button>
    <uploadDialog ref="uploadDialog" :limit="limit" tip="只能上传pdf文件" accept="application/pdf" :multiple="multiple" @success="success" />
  </div>
</template>
 <script>
import uploadDialog from "@/components/uploadDialog"
export default {
  components: {
    uploadDialog
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 9
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    list () {
      console.log(this.value)
      if (this.value)
        return this.value.split(',')
      else
        return []
    }
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {
    showUploadDialog () {
      this.$refs.uploadDialog.show()
    },
    success (res) {
      let arr = this.list.concat(res);
      this.$emit('input', arr.toString())
    },
    close (tag) {
      let list = this.list;
      const index = list.findIndex(item => tag == item)
      this.list.splice(index, 1)
      this.$emit('input', list.toString())
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>