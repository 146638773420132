/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-28 15:54:52
 * @Module:外部客户管理 
 */

 <template>
  <div>
    <div style="mine-height:68px;background:#fff;dispaly:flex; align-items: center;	padding: 0 16px;overflow:hidden;">
      <searchForm :searchForm.sync="listParams" @submit="getOuterCompanyList" />
    </div>
    <div style="padding:16px;background:#fff;margin-top:24px;border-radius: 4px;padding-top:0px;">
      <div style="display: flex;	justify-content: space-between;	align-items: center;height:56px;">
        <div>外部客户列表</div>
        <span >
          <el-button-group>
            <el-button type="primary" size="small" icon="el-icon-share" v-show="['ADMIN','SUPER'].includes(user.role)" @click="exportData">导出</el-button>
            <el-button type="primary" size="small" @click="add">添加客户</el-button>
          </el-button-group>
        </span>
      </div>
      <el-table size="mini" @sort-change="sortChange" :data="list" tooltip-effect="dark" style="width: 100%;margin-top:10px;">
        <el-table-column label="编号" prop="number" sortable="number" show-overflow-tooltip min-width="100">
          <template slot-scope="scope">{{ scope.row.number ||"--"}}</template>
        </el-table-column>

        <el-table-column label="公司全称" show-overflow-tooltip prop="name">
          <template slot-scope="scope">
            <span class="pointer primary" @click="$router.push({path:'/outerCustomerManagement/'+scope.row.uuid})">{{ scope.row.name||"--" }}</span>
          </template>
        </el-table-column>

        <el-table-column label="微信群名" prop="groupName" show-overflow-tooltip min-width="150">
          <template slot-scope="scope">{{ scope.row.groupName ||"--"}}</template>
        </el-table-column>
        <el-table-column label="对接法务" prop="legalName" show-overflow-tooltip min-widthwidth="60">
          <template slot-scope="scope">{{ scope.row.legalName ||"--"}}</template>
        </el-table-column>
        <el-table-column label="团队" prop="team" show-overflow-tooltip min-widthwidth="120">
          <template slot-scope="scope">{{ scope.row.team ||"--"}}</template>
        </el-table-column>
        <el-table-column label="备注" prop="note" show-overflow-tooltip min-widthwidth="120">
          <template slot-scope="scope">{{ scope.row.note ||"--"}}</template>
        </el-table-column>
        <!-- <el-table-column label="对接法务" prop="legalName" show-overflow-tooltip min-widthwidth="60">
          <template slot-scope="scope">{{ scope.row.legalName ||"--"}}</template>
        </el-table-column> -->

        <el-table-column label="当前状态" prop="signState" show-overflow-tooltip min-width="70">
          <template slot-scope="scope">

            <div v-if="scope.row.state=='1'" style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#0AC258;"></i>
              <el-popover placement="top-start" width="200" trigger="hover">
                <div v-if="scope.row.payments.length">
                  <div>最近缴费:{{scope.row.payments[0].amount}}元</div>
                  <div>有效期:{{scope.row.payments[0].endAt|timeFormat}}</div>
                </div>
                <span slot="reference" class="pointer" style="color:#262626;">正常</span>
              </el-popover>
            </div>
            <div v-else-if="scope.row.state=='0'" style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#FF0D0D;"></i>
              <span style="color:#262626;">停费</span>
            </div>
            <span v-else>--</span>
          </template>
        </el-table-column>

        <!-- <el-table-column label="实收服务费" prop="received" sortable="custom" show-overflow-tooltip width="110">
          <template slot-scope="scope">
            <span class="primary">¥{{ scope.row.received }}</span>
          </template>
        </el-table-column> -->

        <!-- <el-table-column label="谁签的" prop="signerName" show-overflow-tooltip width="140">
          <template slot-scope="scope">{{ scope.row.signerName ||"--"}}</template>
        </el-table-column> -->
        <!-- <el-table-column label="合同状态" prop="signState" show-overflow-tooltip width="70">
          <template slot-scope="scope">
            <div v-if="scope.row.signState=='UNSIGNED'" style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#ccc;"></i>
              <span style="color:#666;">无合同</span>
            </div>
            <div v-else-if="scope.row.signState=='NORMAL'" style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#0AC258;"></i>
              <span style="color:#262626;">正常</span>
            </div>
            <div v-else-if="scope.row.signState=='AAA'" style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#0d72ff;"></i>
              <span style="color:#262626;">AAA</span>
            </div>
            <div v-else-if="scope.row.signState=='CANCELLED'" style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#FF0D0D;"></i>
              <span style="color:#262626;">解除</span>
            </div>
            <span v-else>--</span>
          </template>
        </el-table-column> -->
        <el-table-column label="税咨" prop="tax" show-overflow-tooltip width="60">
          <template slot-scope="scope">
            <el-switch :value="scope.row.tax" active-color="#0AC258"  @click.native="()=>taxChange(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="录入时间" prop="createdAt" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.createdAt">{{ scope.row.createdAt|timeFormat }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>

        <el-table-column label="案件数量" prop="caseCount" sortable="custom" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            <span class="primary pointer" v-if="scope.row.caseCount" @click="$router.push({path:'/outerCaseManagement',query: { name: scope.row.name }})">{{scope.row.caseCount}}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>

        <el-table-column label="累计缴费" prop="amount" sortable="amount" show-overflow-tooltip min-widthwidth="60">
          <template slot-scope="scope">{{ scope.row.amount ||"--"}}</template>
        </el-table-column>

        <el-table-column label="操作" width="110" :resizable="false" >
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="()=>revision(scope.row)">编辑</el-button>
            <el-button type="text" size="mini" @click="()=>deleteData(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <c-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getOuterCompanyList" />
    </div>

    <OuterCustomerManagementForm ref='OuterCustomerManagementForm' @success="getOuterCompanyList" />
  </div>
</template>
 <script>
import OuterCustomerManagementForm from "@/views/OuterCustomerManagement/components/OuterCustomerManagementForm"
import searchForm from "@/views/OuterCustomerManagement/components/searchForm"
import { mapState } from "vuex";
export default {
  components: {
    OuterCustomerManagementForm,
    searchForm
  },
  data () {
    return {
      list: [],
      listParams: {
        keyword: '',
        legalUuid: '',
        state: '',
        team: '',
        // signerUuid: '',
        // accountUuid: '',
        orderBy: '',
        order: ''
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    })
  },
  mounted () {
    this.getOuterCompanyList()
  },
  methods: {
    sortChange ({ order, prop }) {
      if (order) {
        this.listParams.order = order;
        this.listParams.orderBy = prop
      } else {
        this.listParams.order = '';
        this.listParams.orderBy = ''
      }
      this.getOuterCompanyList()
    },
    async getOuterCompanyList () {
      const { current, size } = this.$refs.pagination
      const { listParams } = this
      const { status, data } = await this.$api.getOuterCompanyList({ current, size, ...listParams })
      if (status == 200) {
        this.list = data.records;
        this.$refs.pagination.createPagination(data)
      }
    },
    add () {
      this.$refs.OuterCustomerManagementForm.show({ type: 'add' })
    },
    revision (data) {
      console.log(data)
      const platformIds = data.platforms.map(item => item.platformId)
      const businessIds = data.businesses.map(item => item.businessId)
      this.$refs.OuterCustomerManagementForm.show({ data: { ...data, platformIds, businessIds }, type: 'revision' })
    },
    async deleteData ({ name, uuid }) {
      try {
        await this.$confirm(`此操作将永久删除外部公司《${name}》, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const { status, info } = await this.$api.deleteOuterCompany({ uuid })
        if (status === 200) {
          this.$message({
            type: 'success',
            message: info
          })
          this.getOuterCompanyList()
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async taxChange (res) {
      const { uuid, tax } = res
      const { status, info } = await this.$api.putChangeOuterCompany({
        uuid,
        tax: !tax
      })
      if (status === 200) {
        this.$message({
          type: "success",
          message: info
        })
        this.getOuterCompanyList()
      } else {
        this.$message({
          type: "error",
          message: info
        })
      }
    },
    async exportData () {
      const { status, info } = await this.$api.postExportOuterCompany()
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$store.commit("exportData/setBadge", true)
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
</style>