/*
 * @Author: 曹俊杰 
 * @Date: 2024-07-22 12:47:03 
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2025-03-06 22:04:19
 */
 <template>
  <div class="AccountManagement">
    <div class="top">
      <searchForm
        :searchForm.sync="listParams"
        @submit="getAccountList({current:1})"
        @change="change"
      />
    </div>
    <div
      class="content"
      style="
        padding: 16px;
        background: #fff;
        margin-top: 24px;
        border-radius: 4px;
        padding-top: 0px;
      "
    >
      <div class="title">
        <span>报单列表</span>
        <div>
          <el-button
            type="primary"
            size="small"
            @click="add"
            v-if="[0, 1, 2].includes(user.role)"
            >添加报单</el-button
          >
        </div>
      </div>
      <div class="main">
        <el-table
          size="mini"
          :data="list"
          tooltip-effect="dark"
          style="width: 100%"
          @sort-change="sortChange"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-row :gutter="60" style="padding: 24px">
                <el-col :span="8">
                  <el-descriptions title="报单信息" size="mini">
                    <el-descriptions-item
                      v-if="props.row.promoter"
                      label="发起人"
                      >{{ props.row.promoter.name }}</el-descriptions-item
                    >
                    <el-descriptions-item
                      label="审批人"
                      v-if="props.row.auditor"
                      >{{ props.row.auditor.name }}</el-descriptions-item
                    >
                    <el-descriptions-item
                      label="执行人"
                      v-if="props.row.executor"
                      >{{ props.row.executor.name }}</el-descriptions-item
                    >
                  </el-descriptions>
                </el-col>
              </el-row>

              <el-row :gutter="60" style="padding: 24px; padding-top: 0px">
                <el-col :span="5">
                  <el-table
                    :data="JSON.parse(props.row.preCost)"
                    size="mini"
                    border
                  >
                    <el-table-column prop="label" :label="`前置成本`">
                      <template slot-scope="scope">
                        <div v-if="scope.row.label">{{ scope.row.label }}</div>
                        <div v-else>--</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value" >
                      <template slot-scope="scope">
                        <div v-if="scope.row.value">{{ scope.row.value }}%</div>
                        <div v-else>--</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value" :label="`${priceFormat(preCostNum(props.row))}元`">
                      <template slot-scope="scope">
                        <div v-if="scope.row.value">{{  priceFormat(props.row.amount * (scope.row.value/100)) }}元</div>
                        <div v-else>--</div>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-col>
                <el-col :span="5">
                  <el-table
                    :data="JSON.parse(props.row.restAmount)"
                    size="mini"
                    border
                  >
                    <el-table-column prop="label" label="剩余金额">
                      <template slot-scope="scope">
                        <div v-if="scope.row.label">{{ scope.row.label }}</div>
                        <div v-else>--</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value" label="">
                      <template slot-scope="scope">
                        <div v-if="scope.row.value">{{ scope.row.value }}%</div>
                        <div v-else>--</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="value" :label="`${priceFormat(restAmountNum(props.row))}元`">
                      <template slot-scope="scope">
                        <div v-if="scope.row.value">{{ priceFormat(restAmountNum(props.row) * (scope.row.value/100)) }}元</div>
                        <div v-else>--</div>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column label="报单名称" show-overflow-tooltip prop="name">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.name || "--" }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="报单类型" show-overflow-tooltip prop="type">
            <template slot-scope="scope">
              <span>{{ scope.row.type | taskType }}</span>
            </template>
          </el-table-column>

          <el-table-column label="付款方" show-overflow-tooltip prop="payer">
            <template slot-scope="scope">
              <span>{{ scope.row.payer || "--" }}</span>
            </template>
          </el-table-column>

          <el-table-column label="收款帐户" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.receivingAccount | receivingAccount }}
            </template>
          </el-table-column>
          <el-table-column label="付款方式" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.paymentMethod | paymentMethod }}
            </template>
          </el-table-column>
          <el-table-column label="付款截图" show-overflow-tooltip>
            <template slot-scope="scope">
              <imagePreview :imageUrls="scope.row.images" />
            </template>
          </el-table-column>
          <el-table-column label="金额" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ priceFormat(scope.row.amount) }}
            </template>
          </el-table-column>
          <el-table-column
            label="收款日期"
            show-overflow-tooltip
            prop="paidAt"
            sortable="paidAt"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.paidAt | timeFormat("yyyy-mm-dd") }}</span>
            </template>
          </el-table-column>
          <el-table-column label="款项" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.item || "--" }}
            </template>
          </el-table-column>
          <el-table-column
            label="是否签署合同"
            show-overflow-tooltip
            prop="contractState"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.contractState | contractState }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" show-overflow-tooltip prop="state">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.state == 0" size="mini">审核中</el-tag>
              <el-tag v-if="scope.row.state == 1" size="mini" type="success"
                >执行中</el-tag
              >
              <el-tag v-if="scope.row.state == 2" size="mini" type="info"
                >完成</el-tag
              >
              <el-tag v-if="scope.row.state == 3" size="mini" type="warning"
                >撤回</el-tag
              >
              <el-tag v-if="scope.row.state == 4" size="mini" type="danger"
                >驳回</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="发起人" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.promoter.name || "--" }}
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            show-overflow-tooltip
            prop="createdAt"
            sortable="createdAt"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.createdAt | timeFormat("yyyy-mm-dd hh:MM:ss")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="250" :resizable="false">
            <template slot-scope="scope">
              <div>
                <el-button
                  type="text"
                  size="mini"
                  v-if="edit(scope.row)"
                  @click="() => revision(scope.row)"
                  >重新提交</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  v-if="Withdraw(scope.row)"
                  @click="() => putWithdrawTask(scope.row)"
                  >撤回</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  v-if="Unfinished(scope.row)"
                  @click="() => putUnfinishedTask(scope.row)"
                  >撤销执行</el-button
                >

                <el-button
                  type="text"
                  size="mini"
                  v-if="Audit(scope.row)"
                  @click="() => putAuditTaskBtn(scope.row)"
                  >审核</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  v-if="Finish(scope.row)"
                  @click="() => putFinishTask(scope.row)"
                  >完成</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <c-pagination
        ref="pagination"
        layout="prev, pager, next,sizes"
        @change="getAccountList"
      />
    </div>

    <taskFrom @success="getAccountList" ref="accountManagementForm" />
  </div>
</template>
 <script>
import taskFrom from "@/views/Task/components/taskFrom";
import imagePreview from "@/components/imagePreview";
import searchForm from "@/views/Task/components/searchForm";
import { mapState } from "vuex";
import utils from "@/utils/utils.js"
export default {
  components: {
    taskFrom,
    imagePreview,
    searchForm,
  },
  data() {
    return {
      list: [],
      listParams: {
        receivingAccount: "",
        paidAtStart: "",
        paidAtEnd: "",
        promoterId: "",
        role: "",
        order: "",
        orderBy: "",
        state: "",
      },
    };
  },
  mounted() {
    this.getAccountList();
  },
  filters: {
    taskType(type) {
      switch (type) {
        case 0:
          return "法律管家";
        case 1:
          return "咨询费";
        case 2:
          return "文书合同";
        case 3:
          return "律师函";
        case 4:
          return "非诉指导";
        case 5:
          return "代理案件";
        case 6:
          return "其它";
        default:
          return "--";
      }
    },
    receivingAccount(type) {
      switch (type) {
        case 0:
          return "律所银行卡";
        case 1:
          return "律所易生二维码";

        default:
          return "--";
      }
    },
    contractState(type) {
      switch (type) {
        case 0:
          return "否";
        case 1:
          return "是";

        default:
          return "--";
      }
    },
    paymentMethod(type) {
      switch (type) {
        case 0:
          return "银行卡转帐";
        case 1:
          return "微信扫码";
        case 2:
          return "支付宝扫码";
        case 3:
          return "其它";

        default:
          return "--";
      }
    },
  },
  computed: {
    preCostNum(){ 

    
       return ({preCost,amount})=>{
        const preCostNumList = JSON.parse(preCost).map(item => amount * (item.value / 100));
				if (preCostNumList.length > 0) {
					return preCostNumList.reduce((x, y) => x + y)
				} else {
					return 0
				}
       } 
      
    },
    restAmountNum(){
      
       return (res)=> res.amount - this.preCostNum(res)
      
    },
    ...mapState({
      user: (state) => state.userInfo.user,
    }),
    userInfo_role() {
      return this.$store.state.userInfo.user.role;
    },
    userInfo_accountId() {
      return this.$store.state.userInfo.user.accountId;
    },
    edit() {
      return (detaiDatal) => {
        if (detaiDatal.promoter.accountId == this.userInfo_accountId) {
          if (detaiDatal.state == 4) {
            return true;
          } else if (detaiDatal.state == 3) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      };
    },
    Finish() {
      return (detaiDatal) => {
        console.log(detaiDatal.state);
        if (detaiDatal.state === 1) {
          if (this.userInfo_role === 3) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      };
    },
    Unfinished() {
      return (detaiDatal) => {
        if (detaiDatal.state == 2) {
          if (detaiDatal.executor.accountId == this.userInfo_accountId) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      };
    },
    Audit() {
      return (detaiDatal) => {
        if (detaiDatal.state === 0) {
          if (this.userInfo_role == 0) {
            return true;
          } else if (this.userInfo_role == 2) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      };
    },
    Withdraw() {
      return (detaiDatal) => {
        if (detaiDatal.promoter.accountId == this.userInfo_accountId) {
          if (detaiDatal.state === 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      };
    },
  },
  methods: {
    change([paidAtStart, paidAtEnd]) {
      this.listParams.paidAtStart = paidAtStart;
      this.listParams.paidAtEnd = paidAtEnd;
    },
    sortChange({ order, prop }) {
      if (order) {
        this.listParams.order = order;
        this.listParams.orderBy = prop;
      } else {
        this.listParams.order = "";
        this.listParams.orderBy = "";
      }
      this.getAccountList();
    },
    openimage(url) {
      var newWindow = window.open(
        "",
        "_blank",
        "titlebar=no,menubar=no,location=no"
      );

      // 构建图片预览的HTML
      var imagePreview = '<img src="' + url + '" alt="Image preview" />';

      // 向新窗口写入HTML内容
      if (newWindow) {
        newWindow.document.write(imagePreview);
      }
    },
    add() {
      this.$refs.accountManagementForm.show({ type: "add" });
    },
    revision(data) {
      this.$refs.accountManagementForm.show({ type: "revision", data });
    },
    async getAccountList(Params={}) {
      const { current, size } = this.$refs.pagination;
      const { listParams } = this;
      const { status, data } = await this.$api.getTaskList({
        current,
        size,
        ...listParams,
        ...Params
      });
      if (status == 200) {
        this.list = data.records;
        this.$refs.pagination.createPagination(data);
      }
    },
    //撤回
    async putWithdrawTask(detaiDatal) {
      const { status, info } = await this.$api.putWithdrawTask({
        taskId: detaiDatal.taskId,
      });
      if (status === 200) {
        this.$message({
          type: "success",
          message: info,
        });
        this.getAccountList();
      } else {
        this.$message({
          type: "error",
          message: info,
        });
      }
    },
    //撤销完成
    async putUnfinishedTask(detaiDatal) {
      const { status, info } = await this.$api.putUnfinishedTask({
        taskId: detaiDatal.taskId,
      });
      if (status === 200) {
        this.$message({
          type: "success",
          message: info,
        });
        this.getAccountList();
      } else {
        this.$message({
          type: "error",
          message: info,
        });
      }
    },
    //完成
    async putFinishTask(detaiDatal) {
      try {
        await this.$confirm("是否完成该报单？", "确认信息", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        });
        const { status, info } = await this.$api.putFinishTask({
          taskId: detaiDatal.taskId,
        });
        if (status === 200) {
          this.$message({
            type: "success",
            message: info,
          });
          this.getAccountList();
        } else {
          this.$message({
            type: "error",
            message: info,
          });
        }
      } catch (action) {
        console.log(action);
      }
    },
    async putAuditTaskBtn(detaiDatal) {
      try {
        await this.$confirm("是否通过该报单的审核？", "确认信息", {
          distinguishCancelAndClose: true,
          confirmButtonText: "审核通过",
          cancelButtonText: "审核驳回",
        });
        this.putAuditTask({ ...detaiDatal, pass: true });
      } catch (action) {
        if (action === "cancel") {
          this.putAuditTask({ ...detaiDatal, pass: false });
        }
      }
    },
    priceFormat(num,decimal=2 ){
      return parseFloat((Math.round(num * Math.pow(10, decimal)) / Math.pow(10, decimal)).toFixed(decimal))
    },
    //审核
    async putAuditTask(detaiDatal) {
      const { status, info } = await this.$api.putAuditTask({
        taskId: detaiDatal.taskId,
        pass: detaiDatal.pass,
      });
      if (status === 200) {
        this.$message({
          type: "success",
          message: info,
        });
        this.getAccountList();
      } else {
        this.$message({
          type: "error",
          message: info,
        });
      }
    },
  },
};
</script>
 <style lang='scss' scoped>
.AccountManagement {
  .top {
    height: 68px;
    overflow: hidden;
    background: #fff;
    padding: 0 16px;
  }
  .content {
    background: #fff;
    padding: 16px;
    padding-top: 0;
    .title {
      display: flex;
      line-height: 56px;
      justify-content: space-between;
    }
  }
}
</style>
