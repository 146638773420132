/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-06 19:19:25
 * @Module: 标签
 */
 <template>
  <span>
    <div class="tag" :style="'color:'+colors[type]+';border-color:'+colors[type]">
      <slot />
    </div>
    <!-- <span>,</span> -->
  </span>
</template>
 <script>
export default {
  components: {},
  props: {
    type: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      colors: [
        '#FA525C',
        '#27C2A9',
        '#8185A6',
        '#05A9EB',
        '#FAAF25'
      ]
    };
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.tag {
  border: #f66 solid 1px;
  width: max-content;
  padding: 0px 5px;
  font-size: 12px;
  border-radius: 5px;
  transform: scale(0.7);
  transform-origin: left;
  display: inline-block;
}
</style>