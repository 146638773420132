/* 
 * @Author: 曹俊杰 
 * @Date: 2024-03-13 22:56:05
 * @Module: 意向客户
 */
<template>
  <div class="AccountManagement">
    <div class="top">
      <searchForm :searchForm.sync="listParams" @submit="getList" />
    </div>

    <div class="content">
      <div class="title">
        <span>意向客户列表</span>
        <div>
          <el-button-group>
            <el-button type="primary" size="small" icon="el-icon-plus" @click="add">添加客户</el-button>
            <el-button type="primary" size="small" icon="el-icon-search" @click="()=>$router.push({path:'/customerIntended/search'})">客户查重</el-button>
          </el-button-group>
        </div>
      </div>
      <div class="main">
        <el-table size="mini" :data="list" tooltip-effect="dark" style="width: 100%;margin-top:10px;" :row-style="{height:'80px'}">
          <el-table-column label="客户名称" show-overflow-tooltip prop="companyName">
            <template slot-scope="scope">
              <span>{{ scope.row.companyName||"--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="手机号码" show-overflow-tooltip prop="phone">
            <template slot-scope="scope">
              <span>{{ scope.row.phone||"--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="微信号码" show-overflow-tooltip prop="weixin">
            <template slot-scope="scope">
              <div>
                <p>{{scope.row.weixinName||"--"}}</p>
                <span style="color:#ccc">{{ scope.row.weixin }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="基本情况" show-overflow-tooltip prop="note">
            <template slot-scope="scope">
              <span>{{ scope.row.note||"--" }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="图片" show-overflow-tooltip prop="note" width="275" >
            <template slot-scope="scope">
              <imageList :imageUrls="scope.row.image" v-if="scope.row.image" />
              <span v-else>--</span>
            </template>
          </el-table-column> -->
          <el-table-column label="法务" show-overflow-tooltip prop="account.name">
            <template slot-scope="scope">
              <span>{{ scope.row.account.name||"--" }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="操作" width="60" :resizable="false">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="()=>revision(scope.row)">编辑</el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <c-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getList" />
    </div>

    <customerIntendedForm @success="getList" ref="customerIntendedForm" />

  </div>
</template>
 <script>
import customerIntendedForm from "@/views/CustomerIntended/components/customerIntendedForm"
import searchForm from "@/views/CustomerIntended/components/searchForm"
import imagePreview from "@/components/imagePreview"
import imageList from "@/components/imageList"
export default {
  components: {
    customerIntendedForm,
    searchForm,
    // imagePreview,
    // imageList
  },
  data () {

    return {

      list: [],
      listParams: {
        keyword: '',
        role: '',
        teamId: ''
      }
    };
  },
  mounted () {
    this.getList()
  },
  methods: {
    add () {
      this.$refs.customerIntendedForm.show({ type: 'add' });
    },
    revision (data) {
      this.$refs.customerIntendedForm.show({ type: 'revision', data });
    },
    async getList () {
      const { current, size } = this.$refs.pagination
      const { listParams } = this
      const { status, data } = await this.$api.getClientList({ current, size, ...listParams })
      if (status == 200) {
        this.list = data.records;
        this.$refs.pagination.createPagination(data)
      }

    }
  },
};
 </script>
 <style lang='scss' scoped>
.AccountManagement {
  .top {
    height: 68px;
    overflow: hidden;
    background: #fff;
    padding: 0 16px;
  }
  .content {
    background: #fff;
    margin-top: 24px;
    padding: 16px;
    padding-top: 0;
    .title {
      display: flex;
      line-height: 56px;
      justify-content: space-between;
    }
  }
}
</style>
