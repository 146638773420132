/* 
 * @Author: 曹俊杰 
 * @Date: 2022年8月23日
 * @Module: 流水规模
 */
 <template>
  <div>
    <el-select style="width:100%;" filterable value-key="scaleId" :value="value" placeholder="请选择流水规模" clearable @change="change">
      <el-option v-for="item in scaleList" :key="item.scaleId" :label="item.scale" :value="item.scaleId">
        <span style="float: left">{{ item.scale }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px" @click.stop="()=>onDelete(item)">删除</span>
      </el-option>
    </el-select>
    <el-button type="text" icon="el-icon-circle-plus-outline" size="mini" style="float:right;" round @click="add">添加流水规模</el-button>
  </div>
  <!-- <el-select style="width:100%;" value-key="scaleId" v-model="form.scaleId" placeholder="请选择流水规模">
              <el-option v-for="item in scaleList" :key="item.scaleId" :label="item.scale" :value="item.scaleId">
              </el-option>
            </el-select> -->
</template>
 <script>
export default {
  components: {},
  props: {
    value: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      scaleList: [],
      loading: false
    };
  },

  mounted () {
    this.getScaleList()
  },
  methods: {
    async getScaleList () {
      this.loading = true;
      const { status, data } = await this.$api.getScaleList()
      if (status == 200) {
        this.scaleList = data
      }
      this.loading = false;
    },
    change (value) {
      this.$emit('input', value)
    },
    async add () {
      try {
        const { value: scale } = await this.$prompt('请输入流水规模名称', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
        this.loading = true;
        const { status, info } = await this.$api.postScale({ scale })
        if (status === 200) {
          this.$message({
            type: 'success',
            message: info
          })
          this.getScaleList();
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
      } catch (error) {
        console.log(error)
      }
      this.loading = false;
    },
    async onDelete (res) {
      try {
        await this.$confirm(`此操作将永久删除该选项《${res.scale}》, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.loading = true;
        const { status, info } = await this.$api.deleteScale({ scaleId: res.scaleId })
        if (status === 200) {
          this.$message({
            type: 'success',
            message: info
          })
          this.getScaleList()
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
        this.loading = false;
      } catch (error) {
        console.log(error)
      }

    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>