/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-13 13:26:56
 * @Module: 备选公司（添加案件）
 */
 <template>
  <el-select filterable style="width:100%;" value-key="uuid" :value="value" placeholder="请选择诉讼主体" @change="change" :clearable="clearable">
    <el-option v-for="item in list" :key="item.uuid" :label="item.name" :value="item.uuid">
    </el-option>
  </el-select>
</template>
 <script>
export default {
  components: {},
  props: {
    value: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      list: []
    };
  },
  mounted () {
    this.getCompanyAll();
  },
  methods: {
    async getCompanyAll () {
      const { status, data } = await this.$api.getCompanyAll()
      if (status === 200) {
        this.list = data
      }
    },
    change (res) {
      this.$emit('input', res)
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>