/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-25 10:25:10
 * @Module: 联盟会员
 */
 <template>
  <div>
    <div style="mine-height:68px;background:#fff;dispaly:flex; align-items: center;	padding: 0 16px;overflow:hidden;">
      <searchForm :searchForm.sync="listParams" @submit="getPromoterList" />
    </div>
    <el-row :gutter="20" style="margin-top:20px;">
      <el-col :span="6">
        <div style="padding:16px;background:#fff;border-radius: 4px;padding-top:0px;">
          <div style="display: flex;	justify-content: space-between;	align-items: center;height:56px;">
            <div>会员关系</div>
          </div>
          <el-tree @node-click="nodeClick" default-expand-all node-key="uuid" ref="tree" :data="promoterTreeList" highlight-current :props="defaultProps"></el-tree>
        </div>
      </el-col>
      <el-col :span="18">
        <div style="padding:16px;background:#fff;border-radius: 4px;padding-top:0px;">
          <div style="display: flex;	justify-content: space-between;	align-items: center;height:56px;">
            <div>会员列表</div>
            <span v-if="['ADMIN','SUPER'].includes(user.role)">
              <el-button type="primary" size="small" @click="add">创建会员</el-button>
            </span>
          </div>
          <el-table size="mini" @sort-change="sortChange" :data="list" tooltip-effect="dark" style="width: 100%;margin-top:10px;">
            <el-table-column label="编号" prop="number" sortable="number" show-overflow-tooltip min-width="60">
              <template slot-scope="scope">{{ scope.row.number ||"--"}}</template>
            </el-table-column>

            <el-table-column label="名称" show-overflow-tooltip prop="name">
              <template slot-scope="scope">
                <span class="pointer primary" @click="()=>setCurrentKey(scope.row)">{{ scope.row.name||"--" }}</span>
              </template>
            </el-table-column>

            <el-table-column label="级别" prop="grade" sortable="grade" show-overflow-tooltip min-width="60">
              <template slot-scope="scope">{{ scope.row.grade ||"--"}}</template>
            </el-table-column>
            <el-table-column label="应收培训费" prop="receivable" sortable="receivable" show-overflow-tooltip width="110">
              <template slot-scope="scope">{{ scope.row.receivable ||"--"}}</template>
            </el-table-column>
            <el-table-column label="实收培训费" prop="receipts" sortable="receipts" show-overflow-tooltip width="110">
              <template slot-scope="scope">{{ scope.row.receipts ||"--"}}</template>
            </el-table-column>
            <el-table-column label="来源" prop="source" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.source ||"--"}}</template>
            </el-table-column>
            <el-table-column label="邀请人" prop="inviter.name" show-overflow-tooltip v-if="['ADMIN','SUPER'].includes(user.role)">
              <template slot-scope="scope">{{ scope.row.inviter?scope.row.inviter.name:"--"}}</template>
            </el-table-column>
            <el-table-column label="招生转化" prop="zszh" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.zszh ||"--"}}</template>
            </el-table-column>
            <el-table-column label="成单记录" prop="dealQuantity" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.dealQuantity ||"--"}}</template>
            </el-table-column>
            <el-table-column label="介绍会员" prop="inviteeQuantity" show-overflow-tooltip v-if="['ADMIN','SUPER'].includes(user.role)">
              <template slot-scope="scope">{{ scope.row.inviteeQuantity ||"--"}}</template>
            </el-table-column>
            <el-table-column label="录入日期" prop="createdAt" show-overflow-tooltip>
              <template slot-scope="scope"> <span v-if="scope.row.createdAt">{{
                scope.row.createdAt | timeFormat
              }}</span>
                <span v-else>--</span></template>
            </el-table-column>
            <el-table-column label="备注" prop="note" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.note ||"--"}}</template>
            </el-table-column>

            <el-table-column label="操作" width="110" :resizable="false" v-if="['ADMIN','SUPER'].includes(user.role)">
              <template slot-scope="scope">
                <el-button type="text" size="mini" @click="()=>revision(scope.row)">编辑</el-button>
                <!-- <el-button type="text" size="mini" @click="()=>deleteData(scope.row)">删除</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <c-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getPromoterList" />
        </div>
      </el-col>
    </el-row>
    <promoterForm ref="promoterForm" @success="promoterFormSuccess" />
  </div>
</template>
 <script>
import searchForm from "@/views/Promoter/components/searchForm"
import promoterForm from "@/views/Promoter/components/promoterForm"
import { mapState } from "vuex";
export default {
  components: {
    searchForm,
    promoterForm
  },
  data () {
    return {
      list: [],
      promoterTreeList: [],
      listParams: {
        keyword: '',
        inviterUuid: '',
        grade: '',
        orderBy: '',
        order: ''
      },
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    })
  },
  mounted () {
    this.getPromoterList();
    this.getPromoterTreeList();
  },
  methods: {
    promoterFormSuccess () {
      this.getPromoterList();
      this.getPromoterTreeList();
    },
    // 排序
    sortChange ({ order, prop }) {
      if (order) {
        this.listParams.order = order;
        this.listParams.orderBy = prop
      } else {
        this.listParams.order = '';
        this.listParams.orderBy = ''
      }
      this.getPromoterList()
    },
    add () {
      this.$refs.promoterForm.show({ type: 'add' })
    },
    revision (data) {
      this.$refs.promoterForm.show({
        type: 'revision', data: {
          ...data, inviterUuid: data.inviter ? data.inviter.uuid : null
        }
      })
    },
    nodeClick ({ name }) {
      this.listParams.keyword = name
    },
    async getPromoterList () {
      const { current, size } = this.$refs.pagination
      const { listParams } = this
      const { status, data } = await this.$api.getPromoterList({ current, size, ...listParams })
      if (status == 200) {
        this.list = data.records;
        this.$refs.pagination.createPagination(data)
      }
    },
    async getPromoterTreeList () {
      const { status, data } = await this.$api.getPromoterTreeList()
      if (status == 200) {
        console.log(data)
        this.promoterTreeList = [data];
      }
    },
    setCurrentKey ({ uuid }) {
      this.$refs.tree.setCurrentKey(uuid)
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>