/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-25 11:46:00
 * @Module: 筛选表单
 */
 <template>
  <el-form :inline="true" :model="searchForm" size="mini" style="margin-top:20px;">
    <el-form-item label="会员名称">
      <el-input v-model="searchForm.keyword" placeholder="搜索会员名称或编号" clearable></el-input>
    </el-form-item>
    <el-form-item label="级别">
      <grade v-model="searchForm.grade"></grade>
    </el-form-item>
    <el-form-item label="邀请人">
      <inviter v-model="searchForm.inviterUuid"></inviter>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>
</template>
 <script>
import grade from "./form/grade.vue";
import inviter from "./form/inviter.vue";
export default {
  components: {
    grade,
    inviter
  },
  props: {
    searchForm: {
      type: Object,
      default: () => ({
        keyword: '',
        grade: '',
        inviterUuid: ''
      })
    }
  },
  data () {
    return {
    };
  },
  mounted () { },
  methods: {
    onSubmit () {
      this.$emit('submit')
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>