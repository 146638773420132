/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-29 20:13:07
 * @Module: 分佣类型
 */
  <template>
  <el-select :value="value" placeholder="请选择动帐类型" clearable @change="change" style="width:100%;">
    <el-option v-for="item in list" :key="item.value" :label="item.label" :value="item.value">
    </el-option>
  </el-select>
</template>
 <script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {
      list: [{
        label: '分佣',
        value: 'COMMISSION'
      }, {
        label: '提现',
        value: 'WITHDRAW'
      }]
    };
  },
  mounted () { },
  methods: {
    change (value) {
      this.$emit('input', value)
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>