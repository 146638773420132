/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-12 12:41:21
 * @Module: pdf预览
 */
 <template>
  <div>
    <!-- <el-link v-if="pdfUrls" @click="open" type="primary">{{pdfUrls | sliceUrl}}</el-link> -->
    <div v-if="list.length">
      <div v-for="item in list" :key="item" class="title" @click="()=>open(item)">{{item | sliceUrl}}</div>
    </div>
    <span v-else>--</span>
    <!-- <div v-else>--</div> -->
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    pdfUrls: {
      type: String,
      default: ''
    }
  },
  data () {
    return {};
  },
  computed: {
    list () {
      if (this.pdfUrls)
        return this.pdfUrls.split(',')
      else
        return []
    }
  },
  mounted () { },

  methods: {
    open (pdfUrls) {
      window.open(this.$oss + pdfUrls, '_blank')
    }
  },
};
 </script>
 <style lang='scss' scoped>
.title {
  color: #0062ff;
  cursor: pointer;
}
</style>