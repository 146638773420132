/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-25 21:53:35
 * @Module: 会员来源选择 
 */
 <template>
  <div>
    <el-row>
      <el-col :span="20">
        <el-select style="width:100%;" filterable value-key="businessId" :value="value" placeholder="请选择会员来源" clearable @change="change">
          <el-option v-for="item in sourceList" :key="item.uuid" :label="item.content" :value="item.content">
            <span style="float: left">{{ item.content }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px" @click.stop="()=>onDelete(item)">删除</span>
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-button type="text" size="mini" style="float:right;" round @click="add">添加</el-button>
      </el-col>
    </el-row>

  </div>
  <!-- <el-select value-key="businessId" style="width:100%" v-model="form.businessIds" multiple placeholder="请选择会员来源">
    <el-option v-for="item in sourceList" :key="item.businessId" :label="item.name" :value="item.businessId">
    </el-option>
  </el-select> -->
</template>
 <script>

export default {
  components: {},
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      sourceList: [],
      loading: false
    };
  },

  mounted () {
    this.getSourceList()
  },
  methods: {
    async getSourceList () {
      this.loading = true;
      const { status, data } = await this.$api.getSourceList()
      if (status == 200) {
        this.sourceList = data
      }
      this.loading = false;
    },
    change (value) {
      this.$emit('input', value)
    },
    async add () {
      try {
        const { value: content } = await this.$prompt('请输入会员来源', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
        this.loading = true;
        const { status, info } = await this.$api.postSource({ content })
        if (status === 200) {
          this.$message({
            type: 'success',
            message: info
          })
          this.getSourceList();
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
      } catch (error) {
        console.log(error)
      }
      this.loading = false;
    },
    async onDelete (res) {
      try {
        await this.$confirm(`此操作将永久删除该选项《${res.content}》, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.loading = true;
        const { status, info } = await this.$api.deleteSource({
          uuid: res.uuid
        })
        if (status === 200) {
          this.$message({
            type: 'success',
            message: info
          })
          this.getSourceList()
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
        this.loading = false;
      } catch (error) {
        console.log(error)
      }

    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>