/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-25 17:21:32
 * @Module: 联盟会员表单
 */
 <template>
  <el-dialog :title="type=='add'?'创建会员':type=='revision'?'修改会员':''" :visible.sync="dialogVisible" width="40%" @closed="closed">
    <div style="padding:20px;">
      <el-form size="mini" label-position="top" label-width="120px" :model="form" ref="form">
        <el-row :gutter="30">
          <el-col :span="12" v-if="type==='add'">
            <el-form-item label="手机号码" prop="phone" :rules="{ required: true, message: '手机号码不能为空'}">
              <el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编号" prop="number" :rules="{ required: true, message: '编号不能为空'}">
              <el-input v-model="form.number" placeholder="请输入编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="名称" prop="name" :rules="{ required: true, message: '名称不能为空'}">
              <el-input v-model="form.name" placeholder="请输入名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="级别" prop="grade" :rules="{ required: true, message: '级别不能为空'}">
              <grade v-model="form.grade" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="应收培训费" prop="receivable" :rules="{ required: true, message: '应收培训费不能为空'}">
              <el-input v-model="form.receivable" placeholder="请输入应收培训费"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实收培训费" prop="receipts" :rules="{ required: true, message: '实收培训费不能为空'}">
              <el-input v-model="form.receipts" placeholder="请输入实收培训费"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邀请人" prop="inviterUuid" :rules="{ required: true, message: '邀请人不能为空'}">
              <inviter v-model="form.inviterUuid" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="来源" prop="source" :rules="{ required: true, message: '来源不能为空'}">
              <!-- <source v-model="form.source" /> -->
              <sourcea v-model="form.source" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="招生转化" prop="zszh" :rules="{ required: true, message: '招生转化不能为空'}">
              <el-input v-model="form.zszh" placeholder="请输入招生转化"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="note" :rules="{ required: true, message: '备注不能为空'}">
              <el-input v-model="form.note" type="textarea" :autosize="{minRows: 4, maxRows: 12}" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
 <script>
import grade from "./form/grade.vue";
import inviter from "./form/inviter.vue";
import sourcea from "./form/source.vue";
export default {
  components: {
    grade,
    inviter,
    sourcea
  },
  data () {
    return {
      type: 'add',
      dialogVisible: false,
      form: {
        phone: '',
        promoterUuid: null,


        number: null,
        name: null,
        grade: null,
        receivable: null,
        receipts: null,
        inviterUuid: null,
        source: null,
        zszh: null,
        note: null,
      },
    };
  },
  mounted () { },
  methods: {
    show ({ type = "add", data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
      }
      this.type = type;
      this.dialogVisible = true;
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.postPromoter()
        } else {
          return false
        }
      })
    },
    closed () {
      this.form = {
        phone: '',
        promoterUuid: null,


        number: null,
        name: null,
        grade: null,
        receivable: null,
        receipts: null,
        inviterUuid: null,
        source: null,
        zszh: null,
        note: null,
      }
      this.$refs.form.resetFields()
    },
    // 添加
    async postPromoter () {
      const api = {
        revision: {
          func: this.$api.putPromoter,
          params: {
            ...this.form,
            promoterUuid: this.form.uuid
          }
        },
        add: {
          func: this.$api.postPromoter,
          params: {
            ...this.form,
          }
        }
      }
      const { status, data, info } = await api[this.type].func(api[this.type].params)
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success', this.type)
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }

    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>