/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-13 13:06:26
 * @Module: 案件表单
 */
 <template>
  <el-dialog :title="type == 'add' ? '添加案件' : type == 'revision' ? '修改案件' : ''" :visible.sync="dialogVisible" width="800px" @closed="closed">
    <div style="padding: 20px">
      <el-form size="mini" label-position="left" label-width="120px" :model="form" ref="form">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item v-if="type === 'add'" label="诉讼主体" prop="companyUuid" :rules="{ required: true, message: '诉讼主体不能为空' }">
              <company v-model="form.companyUuid" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收费方式" prop="chargingMethod">
              <chargingMethod v-model="form.chargingMethod" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="对方" prop="opponent">
              <el-input v-model="form.opponent" placeholder="请输入对方"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="渠道" prop="channel">
              <channel v-model="form.channel" />
              <!-- <el-select v-model="form.channel" placeholder="请选择渠道" style="width: 100%">
                <el-option key="是" label="是" value="是" />
                <el-option key="否" label="否" value="否" />
              </el-select> -->
              <!-- <el-input v-model="form.channel" placeholder="请输入渠道"></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编号" prop="number">
              <el-input v-model.number="form.number" :maxlength="16" placeholder="请输入编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开庭方式" prop="hearMode">
              <el-input v-model="form.hearMode" placeholder="请输入开庭方式"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="主张金额" prop="proposal">
              <el-input v-model.number="form.proposal" placeholder="请输入主张金额"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="承办律师" prop="lawyer">
              <el-input v-model="form.lawyer" placeholder="请输入承办律师"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="团队" prop="team">
              <team v-model="form.team" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="案由" prop="cause">
              <el-input v-model="form.cause" placeholder="请输入案由"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实际权益金额" prop="actualAmount">
              <el-input v-model.number="form.actualAmount" placeholder="请输入实际权益金额"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同版本" prop="contractEdition">
              <contractEdition v-model="form.contractEdition" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="服务费" prop="serviceFee">
              <el-input v-model.number="form.serviceFee" placeholder="请输入服务费"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="资料链接" prop="documentUrl">
              <el-input v-model="form.documentUrl" placeholder="请输入资料链接"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="案件状态" prop="state">
              <!-- <el-input v-model.number="form.serviceFee" placeholder="请输入服务费"></el-input> -->
              <el-select v-model="form.state" placeholder="请选择案件状态" style="width: 100%">
                <el-option key="1" label="正常进行" :value="1" />
                <el-option key="2" label="暂缓" :value="2" />
                <el-option key="3" label="和解" :value="3" />
                <el-option key="4" label="结案" :value="4" />
                <el-option key="6" label="无法受理" :value="6" />
                <el-option key="5" label="其它" :value="5" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审理机关" prop="inquisitor">
              <inquisitor v-model="form.inquisitor" />
              <!-- <el-input v-model="form.inquisitor" placeholder="请输入审理机关"></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="服务费已支付" prop="feePaid">
              <el-select v-model="form.feePaid" placeholder="服务费是否支付" clearable style="width:100%;">
                <el-option label="已支付" :value="true">
                </el-option>
                <el-option label="未支付" :value="false">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="前期费用已支付" prop="reimbursed">
              <el-select v-model="form.reimbursed" placeholder="前期费用是否支付" clearable style="width:100%;">
                <el-option label="已支付" :value="true">
                </el-option>
                <el-option label="未支付" :value="false">
                </el-option>
              </el-select>
            </el-form-item>

          </el-col>
					 <el-col :span="12">
            <el-form-item label="备注" prop="note">
              <el-input :type="type == 'add'?'textarea':'input'" :autosize="{ minRows: 4, maxRows: 12 }" v-model="form.note" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="执行程序进度" prop="enforcementProgress">
              <el-input type="textarea" v-model="form.enforcementProgress" :autosize="{ minRows: 4, maxRows: 12 }" placeholder="请输入执行程序进度"></el-input>
            </el-form-item>
          </el-col>
         
          <el-col :span="24">
            <el-form-item label="合同文件" prop="judgmentUrls">
              <contractUrls v-model="form.judgmentUrls" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
 <script>
import company from "./form/company.vue";
import inquisitor from "./form/inquisitor.vue";
import chargingMethod from "./form/chargingMethod.vue";
import contractEdition from "./form/contractEdition.vue";
import channel from "./form/channel.vue";
import team from "./form/team.vue";
import contractUrls from "@/views/CustomerManagement/components/form/contractUrls"
export default {
  components: {
    company,
    inquisitor,
    chargingMethod,
    contractEdition,
    team,
    channel,
    contractUrls
  },
  data () {
    return {
      type: "add",
      dialogVisible: false,
      form: {
        companyUuid: "",
        opponent: "",
        proposal: "",
        team: "",
        cause: "",
        contractEdition: "",
        documentUrl: "",
        inquisitor: "",
        chargingMethod: "",
        channel: "",
        hearMode: "",
        lawyer: "",
        note: "",
        actualAmount: "",
        serviceFee: "",
        enforcementProgress: "",
        number: "",
        state: 1,
        feePaid: false,
        reimbursed: false,
				judgmentUrls:''
      },
      //rules前端验证
    };
  },
  mounted () { },
  methods: {
    show ({ type = "add", data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
      }
      this.type = type;
      this.dialogVisible = true;
    },
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postLawsuit();
        } else {
          return false;
        }
      });
    },
    closed () {
      this.form = {
        companyUuid: "",
        opponent: "",
        proposal: "",
        team: "",
        cause: "",
        contractEdition: "",
        documentUrl: "",
        inquisitor: "",
        chargingMethod: "",
        channel: "",
        hearMode: "",
        lawyer: "",
        note: "",
        actualAmount: "",
        serviceFee: "",
        enforcementProgress: "",
        number: "",
        state: 1,
        feePaid: false,
        reimbursed: false,
				judgmentUrls:''
      };
    },
    async postLawsuit () {
      const api = {
        revision: {
          func: this.$api.putChangeLawsuit,
          params: {
            ...this.form,
            lawsuitUuid: this.form.uuid,
          },
        },
        add: {
          func: this.$api.postLawsuit,
          params: {
            ...this.form,
          },
        },
      };

      const { status, data, info } = await api[this.type].func(
        api[this.type].params
      );
      if (status === 200) {
        this.$message({
          type: "success",
          message: info,
        });
        this.$emit("success", this.type);
        this.dialogVisible = false;
      } else {
        this.$message({
          type: "error",
          message: info,
        });
      }
    },
  },
};
</script>
 <style lang='scss' scoped>
</style>