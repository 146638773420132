/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-28 18:12:47
 * @Module: 外部案件
 */

<template>
  <div class="CaseManagement">
    <div class="top">
      <searchForm :searchForm.sync="listParams" @submit="getOuterLawsuitList" />
    </div>
    <div class="content">
      <div class="title">
        <el-tabs v-model="listParams.state" @tab-click="getOuterLawsuitList" style="width:100%">
          <el-tab-pane label="正常进行" name="1"></el-tab-pane>
          <el-tab-pane label="暂缓" name="2"></el-tab-pane>
          <el-tab-pane label="和解" name="3"></el-tab-pane>
          <el-tab-pane label="结案" name="4"></el-tab-pane>
          <el-tab-pane label="无法受理" name="6"></el-tab-pane>
          <el-tab-pane label="其他" name="5"></el-tab-pane>
        </el-tabs>
        <span class="btn pointer primary" style="right:86px" v-if="['ADMIN','SUPER'].includes(user.role)" @click="exportData">
          <span class="el-icon-share"></span> 导出
        </span>
        <span class="btn pointer primary"  @click="add">添加案件</span>
      </div>
      <div class="main">
        <el-table row-key="uuid" :data="list" cell-class-name="cell" header-cell-class-name="cell" style="width: 100%;font-size: 10px" size="mini" @sort-change="sortChange">
          <el-table-column label="案件编号" prop="number" width="130" sortable="number">
            <template slot-scope="scope">
              <span class="pointer primary" @click="()=>toDetail(scope.row)">{{scope.row.number ||'--'}}</span>
            </template>
          </el-table-column>

          <el-table-column label="诉讼主体" prop="companyName" width="228" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.companyName ||'--'}}</span>
                <div style="color:#999">主公司：{{scope.row.master.name ||'--'}}</div>
                <div style="color:#999">公司编号：{{scope.row.master.number ||'--'}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="对方" prop="opponent" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.opponent ||'--'}}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="主张金额" prop="proposal" width="100">
            <template slot-scope="scope">
              <span class="primary">¥{{scope.row.proposal ||'--'}}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="主张金额" prop="proposal" width="100">
            <template slot-scope="scope">
              <span class="primary" v-if="scope.row.proposal">¥{{scope.row.proposal}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="已交基础律师费" prop="lawyerCost.amount" width="125">
            <template slot-scope="scope">
              <span class="success" v-if="scope.row.lawyerCost.amount&&scope.row.lawyerCost.paid">¥{{scope.row.lawyerCost.amount}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="团队" prop="team" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.team ||'--'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="合同版本" prop="contractEdition" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.contractEdition ||'--'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="案由" prop="cause">
            <template slot-scope="scope">
              <span>{{scope.row.cause ||'--'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="渠道" prop="channel">
            <template slot-scope="scope">
              <span>{{scope.row.channel ||'--'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="审理机关" prop="inquisitor" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.inquisitor=='北海仲裁委'" class="primary">{{scope.row.inquisitor}}</span>
              <span v-else-if="scope.row.inquisitor=='中卫仲裁委'" class="success">{{scope.row.inquisitor}}</span>
              <span v-else-if="scope.row.inquisitor.length" class="error">{{scope.row.inquisitor}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>

          <el-table-column label="收费方式" prop="chargingMethod" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.chargingMethod ||'--'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="当前进度" prop="currentPhase" min-width="180" sortable="currentPhase" show-overflow-tooltip>
            <template slot-scope="scope">
              <currentPhaseType :item="scope.row.currentPhase" />
            </template>
          </el-table-column>
          <el-table-column label="完成时间" prop="finishedAt" min-width="180" sortable="finishedAt" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.currentPhase?.finishedAt">{{scope.row.currentPhase.finishedAt|timeFormat}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="资料链接" prop="documentUrl" width="100">
            <template slot-scope="scope">
              <a class="pointer primary el-icon-link" style="font-size:14px" :href="scope.row.documentUrl" target="_blank" rel="noopener noreferrer"></a>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="110" :resizable="false" >
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="()=>revision(scope.row)">编辑</el-button>
              <el-button type="text" size="mini" @click="()=>deleteLawsuit(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <c-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getOuterLawsuitList" />
    </div>

    <outerCaseManagementForm @success="getOuterLawsuitList" ref="add" />
    <lawsuitPhaseForm @success="getOuterLawsuitList" ref="lawsuitPhaseForm" />
    <lawsuitCosForm @success="getOuterLawsuitList" ref="lawsuitCosForm" />
    <!-- <lawsuitPaymentForm @success="getOuterLawsuitList" ref="lawsuitPaymentForm" /> -->
    <outerCaseManagementDetail :detail="detail" :show="detailShow" @handleClose="detailShow=false" @editor="revision" @showLawsuitCosForm="showLawsuitCosForm" @deleteLawsuitPayment="deleteLawsuitPayment" @showLawsuitPhaseForm="showLawsuitPhaseForm" @invoicedClick="invoicedClick" />
  </div>
</template>

<script>
import searchForm from "@/views/OuterCaseManagement/components/searchForm"
import outerCaseManagementDetail from "@/views/OuterCaseManagement/components/outerCaseManagementDetail"
import outerCaseManagementForm from "@/views/OuterCaseManagement/components/outerCaseManagementForm";




import lawsuitPhaseForm from "@/views/OuterCaseManagement/components/lawsuitPhaseForm";
import lawsuitCosForm from "@/views/OuterCaseManagement/components/lawsuitCosForm";


// import lawsuitPaymentForm from "@/views/CaseManagement/components/lawsuitPaymentForm";



import currentPhaseType from "@/views/CaseManagement/components/currentPhaseType"
import { mapState } from "vuex";
export default {
  components: {
    outerCaseManagementForm,
    lawsuitPhaseForm,
    lawsuitCosForm,
    // lawsuitPaymentForm,
    searchForm,
    outerCaseManagementDetail,
    currentPhaseType
  },
  data () {
    return {
      list: [],
      listParams: {
        keyword: '',
        team: '',
        contractEdition: '',
        inquisitor: '',
        chargingMethod: '',
        currentPhase: '',
        order: '',
        orderBy: '',
        state: "1",
				lawyer:''
      },
      uuid: '',
      detailShow: false
    }
  },
  mounted () {
    const { name } = this.$route.query;
    if (name) {
      this.listParams.keyword = name;
      this.$router.push({ query: {} })
    }
    this.getOuterLawsuitList();
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    }),
    detail () {
      if (this.uuid) {
        const index = this.list.findIndex(item => item.uuid === this.uuid);
        return this.list[index]
      } else {
        return ({})
      }
    }
  },
  methods: {
    // 排序
    sortChange ({ order, prop }) {
      if (order) {
        this.listParams.order = order;
        this.listParams.orderBy = prop
      } else {
        this.listParams.order = '';
        this.listParams.orderBy = ''
      }
      this.getOuterLawsuitList()
    },
    // 获取案件列表
    async getOuterLawsuitList () {
      const { current, size } = this.$refs.pagination
      const { listParams } = this
      const { status, data } = await this.$api.getOuterLawsuitList({ current, size, ...listParams });
      if (status === 200) {
        this.list = data.records
        this.$refs.pagination.createPagination(data)
      }
    },
    // 添加案件
    add () {
      this.$refs.add.show({ type: 'add' })
    },
    // 编辑案件
    revision (data) {
      this.$refs.add.show({ type: 'revision', data })
    },
    // 编辑收费进度
    showLawsuitPhaseForm (data) {
      this.$refs.lawsuitPhaseForm.show({ type: 'revision', data })
    },
    //案件发票点击
    async invoicedClick (data) {
      const { status, info } = await this.$api.putChangeOuterLawsuitCost({
        ...data,
        lawsuitUuid: data.uuid,
        invoiced: true
      })
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.getOuterLawsuitList();
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },

    // 编辑前期费用
    showLawsuitCosForm (data) {
      this.$refs.lawsuitCosForm.show({ type: 'revision', data })
    },
    // // 添加/编辑 案件服务费
    // showLawsuitPaymentForm (data) {
    //   this.$refs.lawsuitPaymentForm.show(data)
    // },
    // 删除案件
    async deleteLawsuit ({ uuid, number }) {
      try {
        await this.$confirm(`此操作将永久删除编号为《${number}》的案件，是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const { status, info } = await this.$api.deleteLawsuit({ uuid })
        if (status == 200) {
          this.$message({
            message: info,
            type: 'success'
          })
          this.getOuterLawsuitList()
        } else {
          this.$message({
            message: info,
            type: 'error'
          })
        }
      } catch (error) {
        console.log(error)
      }

    },
    toDetail (data) {
      const { uuid } = data
      this.uuid = uuid;
      this.detailShow = true;
    },
    async deleteLawsuitPayment ({ uuid, batch }) {
      try {
        await this.$confirm(`此操作将永久删除《${batch}》，是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })

        const { status, info } = await this.$api.deleteLawsuitPayment({ uuid })
        if (status == 200) {
          this.$message({
            message: info,
            type: 'success'
          })
          this.getOuterLawsuitList()
        } else {
          this.$message({
            message: info,
            type: 'error'
          })
        }
      } catch (error) {
        console.log(error)
      }

    },
    async exportData () {
			 const { state } = this.listParams
      const { status, info } = await this.$api.postExportOuterLawsuit({ state:+state })
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$store.commit("exportData/setBadge", true)
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.CaseManagement {
  // &/deep/.cell {
  //   padding: 0 !important;
  //   text-align: center;
  // }
  .el-icon-success {
    font-size: 18px;
    color: rgb(12, 182, 23);
  }
  .top {
    background: #fff;
    overflow: hidden;
    padding: 0 16px;
  }
  .content {
    background: #fff;
    margin-top: 24px;
    .title {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      font-weight: 400;
      font-size: 16px;
      position: relative;
      .btn {
        font-size: 14px;
        height: 24px;
        position: absolute;
        right: 16px;
        top: 0px;
        bottom: 0px;
        margin: auto;
      }
    }
    .main {
      padding: 16px;
      padding-top: 0;
    }
  }
}
</style>