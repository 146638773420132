import Router from '@/router'
const state = {
	user: {
		accountId: null,
		address: "",
		contact: "",
		createdAt: null,
		mobile: "",
		name: "",
		phone: "",
		role: null,
		token: "",
		updatedAt: null,
		uuid: "",
		wx: "",
	}
}

const mutations = {
	setUserInfo(state, payload) {
		state.user = {
			...state.user,
			...payload
		};
		// state.commit("userLog/saveUserLog", state.user)
	},
	logout(state) {
		state.user = {
			accountId: null,
			address: "",
			contact: "",
			createdAt: null,
			mobile: "",
			name: "",
			phone: "",
			role: null,
			token: "",
			updatedAt: null,
			uuid: "",
			wx: "",
		}
		Router.push({
			path: '/login', query: { redirect: Router.path }
		})
		window.clearVuexAlong();
	}

}
const actions = {
	setUserInfo({ commit, state, dispatch }, params) {
		commit('setUserInfo', params);
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations
}