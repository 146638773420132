/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-11 18:13:16
 * @Module: 筛选表单
 */
 <template>
  <el-form :inline="true" label-suffix=":" :model="searchForm" size="mini" style="margin-top:20px;">
    <el-form-item label="分成账户">
      <accountShare v-model="searchForm.accountUuid" />
      <!-- <el-input v-model="searchForm.keyword" placeholder="公司名称"></el-input> -->
    </el-form-item>

   

    <el-form-item>
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>

</template>
 <script>
import accountShare from "@/views/CustomerManagement/components/form/accountShare";
export default {
  components:{
    accountShare,
  },

  props: {
    searchForm: {
      type: Object,
      default: () => ({
        accountUuid: ''
      })
    }
  },
  data () {
    return {

    };
  },
  mounted () { },
  methods: {
    onSubmit () {
      this.$emit('submit')
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>