/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-17 10:29:50
 * @Module: 筛选表单
 */
 <template>
  <el-form :inline="true" :model="searchForm" size="mini" style="margin-top:20px;">
    <el-form-item label="关键词">
      <el-input v-model="searchForm.keyword" placeholder="搜索公司名称或案件编号" clearable></el-input>
    </el-form-item>
    <el-form-item label="承办律师">
      <el-input v-model="searchForm.lawyer" placeholder="搜索承办律师姓名" clearable></el-input>
    </el-form-item>

    <el-form-item label="团队">
      <el-select v-model="searchForm.team" filterable placeholder="请选择团队" clearable>
        <el-option v-for="item in teams" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="合同版本">
      <el-select v-model="searchForm.contractEdition" filterable placeholder="请选择合同版本" clearable>
        <el-option v-for="item in contractEditions" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="审理机关">
      <el-select v-model="searchForm.inquisitor" filterable placeholder="请选择审理机关" clearable>
        <el-option v-for="item in inquisitors" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="收费方式">
      <el-select v-model="searchForm.chargingMethod" filterable placeholder="请选择收费方式" clearable>
        <el-option v-for="item in chargingMethods" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="当前进度">
      <el-select v-model="searchForm.currentPhase" filterable placeholder="请选择当前进度" clearable>
        <el-option v-for="item in currentPhases" :key="item.number" :label="item.content" :value="item.number">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="渠道">
      <channel v-model="searchForm.channel" />
      <!-- <el-select v-model="searchForm.currentPhase" filterable placeholder="请选择当前进度" clearable>
        <el-option v-for="item in currentPhases" :key="item" :label="item" :value="item">
        </el-option>
      </el-select> -->
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>

</template>
 <script>
import channel from "./form/channel.vue";
export default {
  components: {
    channel
  },
  props: {
    searchForm: {
      type: Object,
      default: () => ({
        keyword: '',
				lawyer:'',
        team: '',
        contractEdition: '',
        inquisitor: '',
        chargingMethod: '',
        currentPhase: '',
        channel: ''
      })
    }
  },
  data () {
    return {
      chargingMethods: [],
      contractEditions: [],
      currentPhases: [],
      inquisitors: [],
      teams: []
    };
  },
  mounted () {
    this.getLawsuitFilter();
  },
  methods: {
    async getLawsuitFilter () {
      const { data, status } = await this.$api.getLawsuitFilter()
      if (status === 200) {
        this.chargingMethods = data.chargingMethods;
        this.contractEditions = data.contractEditions;
        this.currentPhases = data.currentPhases;
        this.inquisitors = data.inquisitors;
        this.teams = data.teams;
      }
    },

    onSubmit () {
      this.$emit('submit')
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>