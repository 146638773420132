/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-06 18:49:56
 * @Module: 排行
 */
 <template>
  <div class="ranking">
    <div class="title">TOP10 数据总览</div>
    <el-row :gutter="18">
      <el-col :span="6">
        <div class="content">
          <div class="content-title">
            <div class="bg"></div>
            <span>客户</span>
            总数
          </div>
          <div class="content-item" v-for="(item,index) in companyDashboard.totalTop10" :key="index">
            <div class="content-item-l ">
              <div v-if="index < 3" :class="'num' + index"></div>
              <div v-else class="num">{{index+1}}</div>
            </div>
            <div class="content-item-c">{{item.name}}</div>
            <div class="content-item-r">{{item.count}}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="content">
          <div class="content-title">
            <div class="bg"></div>
            <span>本月客户</span>
            新增
          </div>
          <div class="content-item" v-for="(item,index) in companyDashboard.incrementTop10" :key="index">
            <div class="content-item-l ">
              <div v-if="index < 3" :class="'num' + index"></div>
              <div v-else class="num">{{index+1}}</div>
            </div>
            <div class="content-item-c">{{item.name}}</div>
            <div class="content-item-r">{{item.count}}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="content">
          <div class="content-title">
            <div class="bg"></div>
            <span>案件</span>
            总数
          </div>
          <div class="content-item" v-for="(item,index) in lawsuitDashboard.totalTop10" :key="index">
            <div class="content-item-l ">
              <div v-if="index < 3" :class="'num' + index"></div>
              <div v-else class="num">{{index+1}}</div>
            </div>
            <div class="content-item-c">{{item.name}}</div>
            <div class="content-item-r">{{item.count}}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="content">
          <div class="content-title">
            <div class="bg"></div>
            <span>本月案件</span>
            新增
          </div>
          <div class="content-item" v-for="(item,index) in lawsuitDashboard.incrementTop10" :key="index">
            <div class="content-item-l ">
              <div v-if="index < 3" :class="'num' + index"></div>
              <div v-else class="num">{{index+1}}</div>
            </div>
            <div class="content-item-c">{{item.name}}</div>
            <div class="content-item-r">{{item.count}}</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    companyDashboard: {
      type: Object,
      default: () => ({
        totalTop10: [],
        incrementTop10: []
      })
    },
    lawsuitDashboard: {
      type: Object,
      default: () => ({
        totalTop10: [],
        incrementTop10: []
      })
    },

  },
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.ranking {
  width: 100%;
  height: 532px;
  background: #ffffff;
  border-radius: 4px;
  padding: 16px;
  padding-top: 0;
  .title {
    font-size: 16px;
    line-height: 56px;
    color: #262626;
  }
  .content {
    padding: 16px;
    padding-top: 0px;
    // background: #fafafa;
    position: relative;
    height: 456px;
    background-image: url(~@/static/img/home_bg_6.png);
    background-size: 400px auto;
    background-repeat: no-repeat;
    .bg {
      background-image: url(~@/static/img/home_bg_5.png);
      position: absolute;
      width: 110px;
      height: 28px;
      right: 0px;
      top: 0px;
    }
    &-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 56px;
      color: #666;
      span {
        color: #0d72ff;
      }
    }
    &-item {
      display: flex;
      height: 40px;
      align-items: center;

      &-l {
        width: 20px;
        display: flex;
        justify-content: center;

        font-size: 13px;
        line-height: 20px;
        /* identical to box height */

        color: #999999;
        .num {
          background-size: 20px 17px;
          background-repeat: no-repeat;
          width: 20px;
          height: 17px;
          text-align: center;
        }
        .num0 {
          background-image: url(~@/static/img/home_number1.png);
          @extend .num;
        }
        .num1 {
          background-image: url(~@/static/img/home_number2.png);
          @extend .num;
        }
        .num2 {
          background-image: url(~@/static/img/home_number3.png);
          @extend .num;
        }
      }
      &-c {
        flex: 1;
        margin: 0 10px;
        font-size: 14px;
        line-height: 21px;
        color: #262626;
      }
      &-r {
        font-size: 14px;
        line-height: 21px;
        color: #262626;
      }
    }
  }
}
</style>