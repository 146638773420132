/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-13 18:03:30
 * @Module: 收费方式
 */
 <template>
  <div>
    <el-autocomplete
      class="inline-input"
      style="width: 100%"
      :value="value"
      @input="change"
      :fetch-suggestions="querySearch"
      placeholder="请输入收费方式"
      :clearable="clearable"
      @select="handleSelect"
    ></el-autocomplete>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    value: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    change(res) {
      this.$emit("input", res);
    },
    querySearch(queryString, cb) {
      const restaurants = [
        { label: "BD-Z0-P10", value: "BD-Z0-P10" },
        { label: "BD-Z5", value: "BD-Z5" },
        { label: "BD-Z10", value: "BD-Z10" },
        { label: "DBD-Z0-P20", value: "DBD-Z0-P20" },
        { label: "DBD-Z10", value: "DBD-Z10" },
        { label: "D-Z0-P40", value: "D-Z0-P40" },
        { label: "D-Z15", value: "D-Z15" },
        { label: "D-Z0-P50", value: "D-Z0-P50" },
        { label: "D-Z9-P21", value: "D-Z9-P21" },
        { label: "D-Z10", value: "D-Z10" },
      ];
      var results = queryString
        ? restaurants.filter((item) => item.value.indexOf(queryString) == 0)
        : restaurants;
      console.log(results);
      cb(results);
    },
    handleSelect(item) {
      console.log(item);
    },
  },
};
</script>
 <style lang='scss' scoped>
</style>