import axios from "axios"
import store from '@/store'
import debounce from '@/utils/debounce'
// import Router from '@/router'
import { Loading, Message } from 'element-ui';
import queryParams from '@/utils/queryParams';


axios.defaults.timeout = 0
// http request 请求拦截器
axios.interceptors.request.use(
	config => {
		const { token } = store.state.userInfo.user
		token && (config.headers['Authorization'] = 'Bearer ' + token)
		return config
	},
	err => {
		return Promise.reject(err)
	}
)
//http response 响应拦截器
axios.interceptors.response.use(
	response => {
		return response
	},
	error => {
		return Promise.resolve(error.response)
	}
)
//check http状态码
function checkStatus(response) {
	if (response && response.data && response.data.status === 401) {

		debounce(() => Message({
			message: response.data.info
		}))

		return response
	}

	if (response && ((response.status >= 200 && response.status < 300) || response.status === 304 || response.status === 400)) {
		return response.data
	}

}
//check apiCode码
function checkCode(res) {
	return res
}

// loading  
let loadingCount = 0;
let loading;

const startLoading = () => {
	loading = Loading.service({
		lock: true,
		text: '加载中……',
		background: 'rgba(0, 0, 0, 0.3)'
	});
};

const endLoading = () => {
	loading.close();
};

const showLoading = () => {
	if (loadingCount === 0) {
		startLoading();
	}
	loadingCount += 1;
};

const hideLoading = () => {
	if (loadingCount <= 0) {
		return;
	}
	loadingCount -= 1;
	if (loadingCount === 0) {
		endLoading();
	}
};

export default {
	post(url, data) {
		showLoading();
		return axios({
			method: "post",
			url,
			data,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			},
		})
			.then(response => {
				hideLoading();
				return checkStatus(response)
			})
			.then(res => {
				hideLoading();
				return checkCode(res)
			})
	},
	put(url, data) {
		showLoading();
		console.log(data)
		return axios({
			method: "put",
			url,
			data,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			},
		})
			.then(response => {
				hideLoading();
				return checkStatus(response)
			})
			.then(res => {
				hideLoading();
				return checkCode(res)
			})
	},
	delete(url, data) {
		showLoading();
		return axios({
			method: "delete",
			url: url + queryParams(data),

			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			},
		})
			.then(response => {
				hideLoading();
				return checkStatus(response)
			})
			.then(res => {
				hideLoading();
				return checkCode(res)
			})
	},
	upload(url, data) {
		showLoading();

		return axios({
			method: "post",
			url,
			data,
		})
			.then(response => {
				hideLoading();
				return checkStatus(response)
			})
			.then(res => {
				hideLoading();
				return checkCode(res)
			})
	},
	get(url, data, showLoad = true) {
		showLoad && showLoading();
		return axios({
			method: "get",
			url: url + queryParams(data)
		})
			.then(response => {
				showLoad && hideLoading();
				return checkStatus(response)
			})
			.then(res => {
				showLoad && hideLoading();
				return checkCode(res)
			})
	}
}
