/* 
 * @Author: 曹俊杰 
 * @Date: 2024-03-14 00:45:59
 * @Module: 图片列表
 */
 <template>
  <div style="font-size:0px;line-height: 0px;" v-if="imageList.length">
    <!-- <el-image v-for="(item,index) in imageList" :key="index" :preview-src-list="imageList" :src="item" style="width:80px;height:80px;"></el-image> -->
    <el-image :preview-src-list="imageList" :src="imageList[0]" :initial-index="0" v-if="imageList.length>0" style="width:80px;height:80px;" >
			<i class="el-icon-loading" slot="placeholder"></i>
		</el-image>
    <el-image :preview-src-list="imageList" :src="imageList[1]" :initial-index="1" v-if="imageList.length>1" style="width:80px;height:80px;margin-left:5px" >
			<i class="el-icon-loading" slot="placeholder"></i>
		</el-image>
    <div class="num">
      <el-image ref="elImage" :preview-src-list="imageList" :src="imageList[2]" v-if="imageList.length>2" style="width:80px;height:80px;" >
				<i class="el-icon-loading" slot="placeholder"></i>

      </el-image>
      <div class="mask" @click="maskClick" v-if="imageList.length>3">+{{imageList.length - 3}}</div>
    </div>

  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    imageUrls: {
      type: String,
      default: ''
    }
  },
  data () {
    return {};
  },
  computed: {
    imageList () {
      return this.imageUrls.split(',').map(item => "https://oss.mcn-open.com/" + item) || []
    },

  },

  mounted () { },
  methods: {
    maskClick () {
      // console.log(this.$refs.elImage.clickHandler)
			this.$refs.elImage.clickHandler()
    }
  },
};
 </script>
 <style lang='scss' scoped>
.num {
  display: inline-block;
  position: relative;
  z-index: 1;
  margin-left: 5px;
  .mask {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 0px;
    left: 0px;
    background: rgba($color: #000000, $alpha: 0.3);
    // background: #ff6;
    z-index: 2;
    font-size: 30px;
    line-height: 80px;
    text-align: center;
    color: #fff;
		cursor: pointer;
  }
}
</style>