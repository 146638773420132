/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-17 10:29:50
 * @Module: 筛选表单
 */
 <template>
  <el-form
    :inline="true"
    :model="searchForm"
    size="mini"
    style="margin-top: 20px"
  >
    <!-- <el-form-item label="关键词">
      <el-input
        v-model="searchForm.keyword"
        placeholder="关键词"
        clearable
      ></el-input>
    </el-form-item> -->
    <el-form-item label="报单状态">
      <el-select
        v-model="searchForm.state"
        filterable
        placeholder="报单状态"
        clearable
      >
        <el-option :key="0" label="审核中" :value="0"> </el-option>
        <el-option :key="1" label="执行中" :value="1"> </el-option>
        <el-option :key="2" label="完成" :value="2"> </el-option>
        <el-option :key="3" label="撤回" :value="3"> </el-option>
        <el-option :key="4" label="驳回" :value="4"> </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="报单发起人">
      <el-select
        v-model="searchForm.promoterId"
        filterable
        placeholder="请选择报单发起人"
        clearable
      >
        <el-option
          v-for="item in accountList"
          :key="item.accountId"
          :label="item.name"
          :value="item.accountId"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="收款日期">
      <el-date-picker
        @change="change"
        value-format="timestamp"
        v-model="value2"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="收款账户">
      <el-select
        v-model="searchForm.receivingAccount"
        filterable
        placeholder="收款账户"
        clearable
      >
        <el-option :key="0" label="律所银行卡" :value="0"> </el-option>
        <el-option :key="1" label="律所易生二维码" :value="1"> </el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>
</template>
 <script>
export default {
  components: {},
  props: {
    searchForm: {
      type: Object,
      default: () => ({
        receivingAccount: "",
        paidAtStart: "",
        paidAtEnd: "",
        promoterId: "",
        state: "",
      }),
    },
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      accountList: [],
      value2: [],
    };
  },
  mounted() {
    this.getIcaseFilter();
  },
  methods: {
    async getIcaseFilter() {
      const { data, status } = await this.$api.getAccountPromoterList();
      if (status === 200) {
        this.accountList = data;
      }
    },
    change(res) {
      console.log(res);
      if (res) {
        this.$emit("change", res);
      } else {
        this.$emit("change", [null, null]);
      }
    },
    onSubmit() {
      this.$emit("submit");
    },
  },
};
</script>
 <style lang='scss' scoped>
</style>
