/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-17 09:53:13
 * @Module: 报单表单
 */
<template>
  <el-dialog
    :title="type == 'add' ? '添加报单' : type == 'revision' ? '重新编辑' : ''"
    :visible.sync="dialogVisible"
    width="800px"
    @closed="closed"
  >
    <div style="padding: 20px; overflow-y: scroll; height: 400px">
      <el-form
        size="mini"
        label-position="top"
        label-width="120px"
        :model="form"
        ref="form"
      >
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item
              label="项目名称"
              prop="name"
              :rules="{ required: true, message: '请输入项目名称' }"
            >
              <el-input
                v-model="form.name"
                placeholder="请输入项目名称"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="项目类型"
              prop="type"
              :rules="{ required: true, message: '请选择项目类型' }"
            >
              <el-select
                v-model="form.type"
                placeholder="请选择项目类型"
                style="width: 100%"
              >
                <el-option
                  v-for="item in [
                    { label: '法律管家', value: 0 },
                    { label: '咨询费', value: 1 },
                    { label: '文书合同', value: 2 },
                    { label: '律师函', value: 3 },
                    { label: '非诉指导', value: 4 },
                    { label: '代理案件', value: 5 },
                    { label: '其它', value: 6 },
                  ]"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="付款方"
              prop="payer"
              :rules="{ required: true, message: '请输入付款方' }"
            >
              <el-input
                v-model="form.payer"
                placeholder="请输入付款方"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="付款方式"
              prop="paymentMethod"
              :rules="{ required: true, message: '请选择付款方式' }"
            >
              <el-select
                v-model="form.paymentMethod"
                placeholder="请选择付款方式"
                style="width: 100%"
              >
                <el-option
                  v-for="item in [
                    { label: '银行卡转帐', value: 0 },
                    { label: '微信扫码', value: 1 },
                    { label: '支付宝扫码', value: 2 },
                    { label: '其它', value: 3 },
                  ]"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="金额"
              prop="amount"
              :rules="{ required: true, message: '金额不能为空' }"
            >
              <el-input
                v-model="form.amount"
                type="number"
     
                clearable
             
                placeholder="请输入金额"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="收款日期"
              prop="paidAt"
              :rules="{ required: true, message: '收款日期不能为空' }"
            >
              <el-date-picker
                style="width: 100%"
                value-format="timestamp"
                placement="bottom-start"
                v-model="form.paidAt"
                type="date"
                placeholder="选择收款日期"
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="款项"
              prop="item"
              :rules="{ required: true, message: '款项不能为空' }"
            >
              <el-input v-model="form.item" placeholder="请输入款项"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              :rules="{ required: true, message: '请选择是否签署合同' }"
              label="是否签署合同"
              prop="contractState"
            >
              <el-select
                v-model="form.contractState"
                placeholder="请选择是否签署合同"
                style="width: 100%"
              >
                <el-option label="不签" :value="0"> </el-option>
                <el-option label="会签" :value="1"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :rules="{ required: true, message: '请选择收款账户' }"
              label="收款账户"
              prop="receivingAccount"
            >
              <el-select
                v-model="form.receivingAccount"
                placeholder="请选择收款账户"
                style="width: 100%"
              >
                <el-option label="律所银行卡" :value="0"> </el-option>
                <el-option label="律所易生二维码" :value="1"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item
              label="付款截图"
              prop="images"
              :rules="{ required: true, message: '请上传付款截图' }"
            >
              <uploadImage v-model="form.images" :limit="3" ref="uploadImage" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item :label="`前置成本：${preCostNum.toFixed(1)}元`">
              <el-button
                type="text"
                v-if="preCostArr.length == 0"
                @click="() => plus('preCostArr')"
                >添加</el-button
              >
              <el-row
                :gutter="36"
                v-for="(item, index) in preCostArr"
                :key="item.id"
                style="margin-bottom: 12px"
              >
                <el-col :span="11">
                  <el-select
                    style="width: 100%"
                    v-model="item.label"
                    filterable
                    allow-create
                    default-first-option
                    placeholder="请选择"
                    @change="
                      (res) =>
                        setValue(
                          { value: res, type: 'label', style: 'preCostArr' },
                          item
                        )
                    "
                  >
                    <el-option
                      v-for="item in namelist"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-input
                    @input="
                      (res) =>
                        setValue(
                          { value: res, type: 'value', style: 'preCostArr' },
                          item
                        )
                    "
                    :value="item.value"
                    placeholder="请输入款项"
                  >
                    <i slot="suffix">%</i>
                  </el-input>
                </el-col>
                <el-col :span="5">
                  金额：{{ (form.amount * (item.value/100)).toFixed(1) }}元
                </el-col>
                <el-col :span="2">
                  <el-button
                    v-if="index + 1 == preCostArr.length"
                    icon="el-icon-plus"
                    type="text"
                    @click="() => plus('preCostArr')"
                  ></el-button>
                  <el-button
                    v-else
                    icon="el-icon-delete"
                    type="text"
                    style="color: red"
                    @click="() => minus(item, 'preCostArr')"
                  ></el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item :label="`剩余金额：${restAmountNum.toFixed(1)}元`">
              <el-button
                type="text"
                v-if="restAmountArr.length == 0"
                @click="() => plus('restAmountArr')"
                >添加</el-button
              >
              <el-row
                :gutter="36"
                v-for="(item, index) in restAmountArr"
                :key="item.id"
                style="margin-bottom: 12px"
              >
                <el-col :span="11">
                  <el-select
                    style="width: 100%"
                    v-model="item.label"
                    filterable
                    allow-create
                    default-first-option
                    placeholder="请选择"
                    @change="
                      (res) =>
                        setValue(
                          { value: res, type: 'label', style: 'restAmountArr' },
                          item
                        )
                    "
                  >
                    <el-option
                      v-for="item in namelist"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-input
                    @input="
                      (res) =>
                        setValue(
                          { value: res, type: 'value', style: 'restAmountArr' },
                          item
                        )
                    "
                    :value="item.value"
                    placeholder="请输入款项"
                  >
                    <i slot="suffix">%</i>
                  </el-input>
                </el-col>
                <el-col :span="5">
                  金额：{{ (restAmountNum * (item.value/100) ).toFixed(1)}}元
                </el-col>
                <el-col :span="2">
                  <el-button
                    v-if="index + 1 == restAmountArr.length"
                    icon="el-icon-plus"
                    type="text"
                    @click="() => plus('restAmountArr')"
                  ></el-button>
                  <el-button
                    v-else
                    icon="el-icon-delete"
                    type="text"
                    style="color: red"
                    @click="() => minus(item, 'restAmountArr')"
                  ></el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
 <script>
import uploadImage from "@/components/uploadImage/uploadImage";
export default {
  components: {
    uploadImage,
  },
  data() {
    return {
      type: "add",
      dialogVisible: false,
      form: {
        taskId: "",
        content: "", //报单内容
        name: "", //项目名称
        type: null, //项目类型: 0-法律管家1-咨询费2-文书合同3-律师函4-非诉指导5-代理案件6-其它
        payer: "", //付款方
        paymentMethod: null, //付款方式: 0-银行卡转帐1-微信扫码2-支付宝扫码3-其它
        amount: null, //金额
        paidAt: null, //收款日期
        item: "", //款项
        contractState: null, //是否签署合同: 0-不签1-会签
        restAmount: "[]", // 剩余金额
        preCost: "[]", // 前置成本
        receivingAccount: null, //收款账户
        images: null,
      },
    };
  },
  mounted() {},
  computed: {
    preCostNum:{
      get(){

				const preCostNumList = this.preCostArr.map(item => this.form.amount * (item.value / 100));
				if (preCostNumList.length > 0) {
					return preCostNumList.reduce((x, y) => x + y)
				} else {
					return 0
				}
      }
    },
    restAmountNum:{
      get(){
       return  this.form.amount - this.preCostNum
      }
    },
    // 完整写法 对象式(可读可改)    有getter和setter
    restAmountArr: {
      get() {
        return JSON.parse(this.form.restAmount);
      },
      set(value) {
        console.log(value);
        this.form.restAmount = JSON.stringify(value);
      },
    },
    namelist: {
      get() {
        return this.$store.state.task.nameList;
      },
      set(value) {
        this.$store.dispatch("task/setNameList", value);
      },
    },

    preCostArr: {
      get() {
        return JSON.parse(this.form.preCost);
      },
      set(value) {
        this.form.preCost = JSON.stringify(value);
      },
    },
  },
  methods: {
    xuanzetupian() {
      this.$refs.uploadImage.uploadDialogShow();
    },
    // getNumIpt(val) {
    //   // 非数字 一位小数点 开头不能是小数
    //   this.form.amount = val
    //     .replace(/[^\d.]/g, "")
    //     .replace(/^(\d+)\.(\d*).*$/, "$1.$2")
    //     .replace(/^\.*$/g, "");
    // },
    // getChangeIpt(val) {
    //   // 处理小数点后无数字情况，例如12.处理为12
    //   this.form.amount = val
    //     .replace(/[^\d.]/g, "")
    //     .replace(/^(\d+)\.(\d\d).*$/, "$1.$2")
    //     .replace(/^\.*$/g, "");
    // },

    show({ type = "add", data }) {
      if (type == "revision") {
        let form = this.$utils.deepClone(data);
        if (form.lawyer) {
          form.lawyer = form.lawyer.split(",");
        }
        this.form = form;
      }
      this.type = type;
      this.dialogVisible = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let bol = true;

          let array = [...this.restAmountArr, ...this.preCostArr];

          for (let i = 0; i < array.length; i++) {
            let element = array[i];
            if (element.label) {
              bol = true;
              if (element.value) {
                bol = true;
              } else {
                bol = false;
                break; // 终止循环
              }
            } else {
              if (element.value) {
                bol = true;
              } else {
                bol = false;
                break; // 终止循环
              }
              bol = false;
              break; // 终止循环
            }
          }
          if (bol) {
            this.postIcase();
            // console.log({
            //   bol,
            //   restAmountArr: this.restAmountArr,
            //   preCostArr: this.preCostArr,
            // });
          } else {
            this.$message({
              type: "error",
              message: "剩余金额和前置成本不要填空值！",
            });
          }
        } else {
          return false;
        }
      });
    },
    close() {
      // this.form = { ...this.form, ...this.oldform };
      this.dialogVisible = false;
    },
    closed() {
      this.form = {
        taskId: "",
        content: "", //报单内容
        name: "", //项目名称
        type: null, //项目类型: 0-法律管家1-咨询费2-文书合同3-律师函4-非诉指导5-代理案件6-其它
        payer: "", //付款方
        paymentMethod: null, //付款方式: 0-银行卡转帐1-微信扫码2-支付宝扫码3-其它
        amount: 0, //金额
        paidAt: null, //收款日期
        item: "", //款项
        contractState: null, //是否签署合同: 0-不签1-会签
        restAmount: "[]", // 剩余金额
        preCost: "[]", // 前置成本
        receivingAccount: null, //收款账户
        images: null,
      };
    },
    async postIcase() {
      const api = {
        revision: {
          func: this.$api.putResubmitTask,
          params: {
            ...this.form,
          },
        },
        add: {
          func: this.$api.postAddTask,
          params: {
            ...this.form,
          },
        },
      };

      const { status, data, info } = await api[this.type].func(
        api[this.type].params
      );

      if (status === 200) {
        this.$message({
          type: "success",
          message: info,
        });
        this.$emit("success", this.type);
        this.dialogVisible = false;
      } else {
        this.$message({
          type: "error",
          message: info,
        });
      }
    },
    plus(type) {
      let arr = this[type];
      console.log("restAmountArr:", this.restAmountArr, "type:", type);
      console.log("arr:", arr);
      arr.push({
        value: "",
        label: "",
        id: this.$utils.guid(),
      });
      this[type] = arr;
    },
    minus({ id }, type) {
      let arr = this[type];
      const index = arr.findIndex((res) => res.id == id);
      arr.splice(index, 1);
      this[type] = arr;
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },

    addNameList(value) {
      let namelist = this.namelist;
      const index = namelist.findIndex((res) => res == value);
      if (index < 0) {
        namelist.push(value);
        this.namelist = namelist;
      }
    },
    setValue({ value, type, style }, { id }) {
      if (type == "label") {
        this.addNameList(value);
      }
      let arr = this[style];
      const index = arr.findIndex((res) => res.id == id);
      arr[index][type] = value;
      this[style] = arr;
    },
  },
};
</script>
 <style lang='scss' scoped>
</style>
