/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-11 18:13:16
 * @Module: 筛选表单
 */
 <template>
  <el-form :inline="true" label-suffix=":" :model="searchForm" size="mini" style="margin-top:20px;">
    <el-form-item label="公司名称">
      <el-input v-model="searchForm.keyword" placeholder="公司名称"></el-input>
    </el-form-item>

    <el-form-item label="经营地">
      <areaCode v-model="searchForm.areaCode" />
    </el-form-item>
    <el-form-item label="法务">
      <legal v-model="searchForm.legalUuid" clearable />
    </el-form-item>
    <el-form-item label="签约人">
      <accountShare v-model="searchForm.signerUuid" />
    </el-form-item>
    <el-form-item label="分成账号">
      <accountShare v-model="searchForm.accountUuid" />
    </el-form-item>
    <el-form-item label="合同状态">
      <signState v-model="searchForm.signState" />
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>

</template>
 <script>
import legal from "./form/legal"
import areaCode from "./form/areaCode"
import accountShare from "./form/accountShare"
import signState from "./form/signState"
export default {
  components: {
    legal,
    areaCode,
    accountShare,
    signState
  },
  props: {
    searchForm: {
      type: Object,
      default: () => ({
        keyword: '',
        areaCode: '',
        legalUuid: '',
        signerUuid: '',
        accountUuid:''
      })
    }
  },
  data () {
    return {

    };
  },
  mounted () { },
  methods: {
    onSubmit () {
      console.log(11)
      this.$emit('submit')
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>