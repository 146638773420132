/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-06 17:51:59
 * @Module: 首页统计
 */
 <template>
  <div class="statistics" :class="type">
    <div class="title">{{title}}</div>
    <div class="bg"></div>
    <div class="num">{{total}}</div>
    <div class="top">
      <span>昨日新增：{{total1}}</span>
      <span style="margin-left:30px;">本周新增：{{total7}}</span>
    </div>
    <div class="bottom">
      <span>本月新增：{{total30}}</span>
    </div>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    title: {
      default: '',
      type: String
    },
    type: {
      default: 'type1',
      type: String
    },
    total: {
      type: Number,
      default: 0
    },
    total1: {
      type: Number,
      default: 0
    },
    total7: {
      type: Number,
      default: 0
    },
    total30: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.statistics {
  height: 176px;
  overflow: hidden;
  border-radius: 4px;
  padding: 16px;
  padding-bottom: 0px;
  position: relative;

  .bg {
    width: 68px;
    height: 68px;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .title {
    font-size: 16px;
    line-height: 24px;
  }
  .num {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
  }
  .top {
    height: 44px;
    border-bottom: 1px solid #eee;
    padding-top: 13px;
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
  }
  .bottom {
    height: 44px;
    padding-top: 13px;
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
  }
}
.type1 {
  background: linear-gradient(180deg, #f2f8ff 0%, #ffffff 53.12%);
  .title {
    color: #0d72ff;
  }
  .num {
    color: #0d72ff;
  }
  .bg {
    background-image: url(~@/static/img/home_bg_1.png);
  }
}
.type2 {
  .title {
    color: #0d72ff;
  }
  .num {
    color: #0d72ff;
  }
  .bg {
    background-image: url(~@/static/img/home_bg_2.png);
  }
  background: linear-gradient(180deg, #f2f8ff 0%, #ffffff 53.12%);
}
.type3 {
  .title {
    color: #faa423;
  }
  .num {
    color: #faa423;
  }
  .bg {
    background-image: url(~@/static/img/home_bg_3.png);
  }
  background: linear-gradient(180deg, #faf5ee 0%, #ffffff 54.69%);
}
.type4 {
  .title {
    color: #faa423;
  }
  .num {
    color: #faa423;
  }
  .bg {
    background-image: url(~@/static/img/home_bg_4.png);
  }
  background: linear-gradient(180deg, #faf5ee 0%, #ffffff 54.69%);
}
</style>