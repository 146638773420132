const state = {
	nameList:[]
}

const mutations = {
	setNameList (state, payload) {
		state.user = payload
	}

}
const actions = {
	setNameList ({ commit, state, dispatch }, params) {
		commit('setNameList', params);
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations
}